import React, { forwardRef } from 'react'
import { connect } from "react-redux"
import { setState } from "../../../redux/actions"
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/styles'
import { useEffect } from 'react'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const StyledDialog = withStyles({
    root: {
        zIndex: '1400 !important',
    }
})(Dialog);

const DialogError = ({ state, setState }) => {
    const handleClose = () => {
        setState({
            ...state,
            openDialogError: false,
            messageDialogError: null
        })
    }

    useEffect(() => {
        if (state.openDialogError) {
        }
    }, [state.openDialogError])

    return (
        <StyledDialog
            open={state.openDialogError}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className="txt-primario">Ocorreu um Erro</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Não foi possível executar sua requisição!
                </DialogContentText>
                <pre id="code-area">{
                    state.messageDialogError !== null
                        ?
                        JSON.parse(JSON.stringify(state.messageDialogError.data.detail, null, 2))
                        : null
                }
                </pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className="bg-alternativo-4 txt-alternativo-3">Fechar</Button>
            </DialogActions>
        </StyledDialog>
    )
}

const mapStateToProps = (data) => ({
    state: data.state
})

const mapDispatchToProps = {
    setState
}

const DialogErrorFetch = connect(mapStateToProps, mapDispatchToProps)(DialogError)

export default DialogErrorFetch