import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { Fragment } from 'react';
import { OutputLink } from '../Typography';

const ViewListRecibos = ({ title, description, recibo, onClick, children }) => {
    return (
        <Fragment>
            <Typography variant="subtitle2" gutterBottom component="div" style={{ wordBreak: 'break-word' }}>
                {title}
            </Typography>
            <Typography variant="body2" gutterBottom component="span" style={{ wordBreak: 'break-word' }}>
                {description}
            </Typography>


            {children ? children : null}

            <List>
                {recibo && (<ListItem key={recibo.name}>
                    <ListItemAvatar>
                        <Avatar>
                            <GetApp />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <OutputLink onClick={() => onClick(recibo)} text={recibo.name} />
                    </ListItemText>
                </ListItem>)}
            </List>
        </Fragment>
    )
}

export { ViewListRecibos }