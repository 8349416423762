import { Box, Divider, FormControl, LinearProgress, Paper, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ViewEditAnexos, ViewListAnexos } from '../../../components/fragments/Anexos';
import { LoadingButtonSend } from '../../../components/fragments/Buttons/Send';
import MessageBox from '../../../components/fragments/MessageBox';
import Snack from '../../../components/fragments/Snack';
import { TableCollapseRow, TablePageable } from '../../../components/fragments/Tables';
import { WarningText } from '../../../components/fragments/Typography';
import SearchBar from '../../../components/inputs/SearchBar';
import Condition from '../../../components/lab/Condition';
import Container from '../../../components/layout/Container';
import EnumSituacaoPeticao from '../../../enums/enumSituacaoPeticao';
import { CompareDate, DateTimeFormat, DownloadArquivo, DownloadLocalFile, HandleErrors } from '../../../functions';
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService';
import UserService from '../../../services/UserService';
import { validaExtensaoArquivo } from '../../../utils/validaExtensaoArquivo';
import { useSnackbar } from 'notistack'

export default function Home(props) {
    const [peticoes, setPeticoes] = useState([]);
    const [filteredPeticoes, setFilteredPeticoes] = useState(null);

    const [success, setSuccess] = useState('');
    const [errors, setErrors] = useState([]);

    const snackbarVariantInfo = 'info'
    const { enqueueSnackbar } = useSnackbar()

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        ProtocoloDigitalService.PeticoesGet()
            .then(response => setPeticoes(buildPeticoes(response.data)))
            .catch((err) => setErrors([`Falha ao buscar petições. ${err.response.data.detail}`]))
            .finally(() => setLoader(false));
    },
        // eslint-disable-next-line
        []);

    const buildPeticoes = (data) => {
        return data
            .sort((first, second) => CompareDate(first.dataRegistro, second.dataRegistro))
            .map(row => {

                let _descricaoSituacaoExibirUsuario = '';

                switch (row.situacao) {
                    case EnumSituacaoPeticao.Cadastrada:
                        _descricaoSituacaoExibirUsuario = 'Pendente de Envio';
                        break;
                    case EnumSituacaoPeticao.Modificada:
                        _descricaoSituacaoExibirUsuario = 'Pendente de Reenvio';
                        break;
                    case EnumSituacaoPeticao.Enviada:
                        _descricaoSituacaoExibirUsuario = 'Enviada';
                        break;
                    case EnumSituacaoPeticao.Reenviada:
                        _descricaoSituacaoExibirUsuario = 'Reenviada';
                        break;
                    case EnumSituacaoPeticao.Rejeitada:
                        _descricaoSituacaoExibirUsuario = 'Rejeitada';
                        break;
                    case EnumSituacaoPeticao.Autuada:
                    case EnumSituacaoPeticao.Protocolada:
                    case EnumSituacaoPeticao.ProtocoladaAutuada:
                        _descricaoSituacaoExibirUsuario = 'Recebida';
                        break;
                    case EnumSituacaoPeticao.Cancelada:
                        _descricaoSituacaoExibirUsuario = 'Cancelada';
                        break;
                    default:
                        _descricaoSituacaoExibirUsuario = '';
                }

                let _numerosProcessosVinculadosChancela = buildProcessosVinculadosChancela(row.solicitacaoAutuacao?.autuacao?.numeroProcesso, row.solicitacaoAutuacao?.protocolo?.listaAutuacoes);
                let _numeroProcessoAutuacao = row.solicitacaoAutuacao?.autuacao?.numeroProcesso;
                let _codigoChancela = row.solicitacaoAutuacao?.protocolo?.codigoChancela;
                let _numeroProcesso = '';

                if (_numeroProcessoAutuacao !== null) {
                    _numeroProcesso = _numeroProcessoAutuacao;

                    if (_numerosProcessosVinculadosChancela !== '') {
                        _numeroProcesso += ", " + _numerosProcessosVinculadosChancela;
                    }
                }
                else {
                    if (_numerosProcessosVinculadosChancela !== '') {
                        _numeroProcesso = _numerosProcessosVinculadosChancela;
                    }
                }

                if (_numeroProcessoAutuacao == null && _codigoChancela == null) {
                    _numeroProcesso = row.numeroProcesso;
                }

                return {
                    id: row.id,
                    solicitacaoAutuacaoId: row.solicitacaoAutuacao.id,
                    dataRegistro: row.dataRegistro ? DateTimeFormat(row.dataRegistro) : null,
                    tipoAssunto: row.tipoAssunto,
                    descricaoTipoAssunto: row.descricaoTipoAssunto,
                    descricaoAssunto: row.descricaoAssunto,
                    situacao: row.situacao,
                    descricaoSituacao: row.descricaoSituacao,
                    descricaoSituacaoExibirUsuario: _descricaoSituacaoExibirUsuario,
                    observacao: row.observacao,
                    motivoRejeicao: row.solicitacaoAutuacao?.motivoRejeicao,
                    chancela: row.solicitacaoAutuacao?.protocolo?.codigoChancela,
                    numeroProcesso: _numeroProcesso
                }
            });
    }

    function buildProcessosVinculadosChancela(autuacao, listaAutuacoesVinculadasProtocolo) {

        let _numerosProcessosVinculadosChancela = '';

        if (listaAutuacoesVinculadasProtocolo.length > 0) {
            for (var indice in listaAutuacoesVinculadasProtocolo) {
                // Apenas quando a número de processo vinculado a chancela não for o mesmo número de processo vinculado diretamente a solicitação de autuação.
                if (listaAutuacoesVinculadasProtocolo[indice].numeroProcesso !== autuacao)
                    _numerosProcessosVinculadosChancela += listaAutuacoesVinculadasProtocolo[indice].numeroProcesso + ", ";
            }
            _numerosProcessosVinculadosChancela = _numerosProcessosVinculadosChancela.substring(0, _numerosProcessosVinculadosChancela.length - 2);
        }
        return _numerosProcessosVinculadosChancela;
    }

    const onSearchPeticoes = (text) => {
        if (text) {
            setFilteredPeticoes(peticoes.filter(peticao =>
                peticao.descricaoTipoAssunto?.includes(text) ||
                peticao.descricaoSituacaoExibirUsuario?.includes(text) ||
                String(peticao.numeroProcesso)?.includes(text) ||
                peticao.chancela?.includes(text) ||
                peticao.observacao?.includes(text) ||
                String(peticao.solicitacaoAutuacaoId)?.includes(text)
            ));
        } else {
            setFilteredPeticoes(peticoes);
        }
    }

    const onClickDownloadAnexos = (peticao, anexo) => {
        if (!anexo.id) {
            DownloadLocalFile(anexo);
            return;
        }

        ProtocoloDigitalService.AnexosPeticaoDownloadGet(peticao, anexo.id)
            .then(response => DownloadArquivo(response.headers['content-type'], response.headers["content-disposition"], response.data))
            .catch(() => setErrors(['Falha ao baixar documento, tente novamente!']));
    }

    const onEnviar = (peticao, anexos, deletedFiles, setLoading, setErrors) => {
        setLoading(true);
        let user = UserService.GetData()

        ProtocoloDigitalService.AnexosPeticaoDelete(peticao.id, deletedFiles.map(file => file.id))
            .then(() => anexos.length ? ProtocoloDigitalService.AnexosPeticaoUploadPost(peticao.id, anexos) : true)
            .then(() => ProtocoloDigitalService.PeticoesPut(peticao.id, user.cpf, peticao.tipoAssunto, peticao.descricaoAssunto, peticao.observacao, peticao.numeroProcesso))
            .then(() => ProtocoloDigitalService.PeticoesEnviarPut(peticao.id))
            .then(() => setSuccess('Petição Enviada!'))
            .then(() => setTimeout(() => window.location.reload(), 2000))
            .catch(error => setErrors(HandleErrors(error?.response?.data?.detail ? error?.response?.data?.detail : error?.message ? error?.message : error)))
            .finally(() => setLoading(false));
    }

    function AnexosViewList({ peticao, anexos }) {
        return (
            <Box style={{ padding: '10px' }}>
                <ViewListAnexos
                    anexos={anexos}
                    onClick={(anexo) => onClickDownloadAnexos(peticao.id, anexo)}
                >
                    <Typography variant="subtitle2" gutterBottom component="div" style={{ wordBreak: 'break-word' }}>
                        Observação
                    </Typography>
                    <Typography variant="body2" gutterBottom component="div" style={{ wordBreak: 'break-word' }}>
                        {peticao.observacao}
                    </Typography>
                    <Divider />
                </ViewListAnexos>
            </Box>
        )
    }

    function AnexosEditList({ peticao, anexos, setAnexos }) {
        const [loading, setLoading] = useState(false);
        const [observacao, setObservacao] = useState(peticao.observacao);
        const [deletedFiles, setDeletedFiles] = useState([]);
        const tamanhoMaximoBytesEnvio = 314572800;

        const onFileRemove = (anexo) => {
            if (!anexo.id) {
                setAnexos(anexos.filter(file => file.name !== anexo.name))
                return;
            }

            setDeletedFiles([...deletedFiles, anexo])
            setAnexos(anexos.filter(file => file.name !== anexo.name))
        }

        const onSend = () => {

            let tamanhoMaximoNomeArquivo = 100
            let listaArquivosValidos = true
            setLoading(true);

            let newAnexos = anexos.filter(anexo => !anexo.id);
            if (!anexos.length) {
                enqueueSnackbar('É necessário anexar pelo menos um arquivo.', { variant: snackbarVariantInfo })
                setLoading(false);
                return;
            }

            let somaBytesTamanhosArquivos = 0;

            newAnexos.forEach(arquivo => {
                if (String(arquivo.name).length > tamanhoMaximoNomeArquivo) {
                    enqueueSnackbar(`O nome do arquivo de anexo deve possuir no máximo ${tamanhoMaximoNomeArquivo} caracteres.`, { variant: snackbarVariantInfo })
                    listaArquivosValidos = false
                }

                if (!validaExtensaoArquivo(arquivo)) {
                    enqueueSnackbar(`O tipo do arquivo de anexo ${arquivo.name} não é permitido.`, { variant: snackbarVariantInfo })
                    listaArquivosValidos = false
                }

                somaBytesTamanhosArquivos += arquivo.size;
            });

            if (somaBytesTamanhosArquivos > tamanhoMaximoBytesEnvio) {
                enqueueSnackbar('Tamanho máximo suportado para envio de anexo(s) é de 300MB', { variant: snackbarVariantInfo })
                listaArquivosValidos = false
            }

            if (listaArquivosValidos === false) {
                setLoading(false);
                return;
            }

            peticao.observacao = observacao;
            onEnviar(peticao, newAnexos, deletedFiles, setLoading, setErrors);
        }

        return (
            <Box style={{ padding: '10px' }}>
                <Condition assert={peticao.situacao === EnumSituacaoPeticao.Rejeitada}>
                    <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*Solicitações rejeitadas podem ser editadas e enviadas novamente.</p>
                </Condition>
                <ViewEditAnexos
                    anexos={anexos}
                    setAnexos={setAnexos}
                    loading={loading}
                    onClick={(anexo) => onClickDownloadAnexos(peticao.id, anexo)}
                    onRemove={(anexo) => onFileRemove(anexo)}
                    tamanhoMaximoNomeAnexo={100}
                >
                    <FormControl>
                        <TextField
                            label="Observação"
                            variant="outlined"
                            minRows={3}
                            maxRows={8}
                            multiline
                            value={observacao}
                            onChange={item => setObservacao(item.target.value)} />
                    </FormControl>

                    <Condition assert={peticao.situacao === EnumSituacaoPeticao.Rejeitada}>
                        <WarningText title='Motivo da Rejeição' value={peticao.motivoRejeicao} />
                    </Condition>
                </ViewEditAnexos>
                <LoadingButtonSend text='Enviar' loading={loading} disabled={!anexos.length || loading} onSend={onSend} />
            </Box>
        )
    }

    function AnexosView({ peticao }) {
        const [anexos, setAnexos] = useState([]);

        const listAnexos = (peticaoId) => {
            ProtocoloDigitalService.AnexosPeticaoListagemGet(peticaoId)
                .then(response => setAnexos(buildAnexos(response.data)))
                .catch((errors) => {
                    if (errors?.response?.status === 404)
                        setAnexos([])
                    else
                        setErrors(['Falha ao listar anexos, tente novamente!'])
                });
        }

        const buildAnexos = (data) => {
            return data.map(anexo => {
                return {
                    id: anexo.anexoPeticaoId,
                    peticaoId: anexo.peticaoId,
                    name: anexo.nomeAnexo
                }
            })
        }

        return (
            <TableCollapseRow
                item={peticao}
                fields={['solicitacaoAutuacaoId', 'numeroProcesso', 'chancela', 'descricaoTipoAssunto', 'dataRegistro', 'descricaoSituacaoExibirUsuario']}
                onOpen={() => listAnexos(peticao.id)}
            >
                {peticao.situacao === EnumSituacaoPeticao.Cadastrada || peticao.situacao === EnumSituacaoPeticao.Rejeitada ?
                    <AnexosEditList props={props} peticao={peticao} anexos={anexos} setAnexos={setAnexos} /> :
                    <AnexosViewList peticao={peticao} anexos={anexos} />
                }
            </TableCollapseRow>
        );
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Petições Enviadas"
        >
            <MessageBox title='Alerta' messages={errors} severity='warning' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <SearchBar label='Pesquisar Petição' onChange={(text) => onSearchPeticoes(text)} />
            </Paper>
            <br /><Divider />
            <LinearProgress hidden={!loader} />
            <TablePageable
                headers={['', 'Solicitação', 'Número do Processo', 'Código da Chancela', 'Tipo de Assunto', 'Data de Registro', 'Situação']}
                items={filteredPeticoes ? filteredPeticoes : peticoes}
                action={(peticao) => (<AnexosView key={peticao.id} peticao={peticao} />)}
            />
        </Container>
    )
}