import Home from '../pages/Home'
import ProtocolarPeticao from '../pages/Peticao/ProtocolarPeticao'
import PeticoesEnviadas from '../pages/Peticao/PeticoesEnviadas'
import ModelosDeFormularios from '../pages/Peticao/ModelosDeFormularios'
import ProtocolarSustentacao from '../pages/SustentacaoOral/ProtocolarSustentacao'
import SustentacoesEnviadas from '../pages/SustentacaoOral/SustentacoesEnviadas'
import InformarDeclarantes from '../pages/DeclaracaoBensRendas/InformarDeclarantes'
import GestaoDeclaracoes from '../pages/DeclaracaoBensRendas/GestaoDeclaracoes'
import ProtocolarDeclaracao from '../pages/DeclaracaoBensRendas/ProtocolarDeclaracao'
import DeclaracoesEnviadas from '../pages/DeclaracaoBensRendas/DeclaracoesEnviadas'
import VistasConcedidas from '../pages/VistaEletronica/VistasConcedidas';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    { path: '/', name: 'Home', Component: Home, },
    { path: '/Home/', Component: Home, },
    { path: '/Peticao/ProtocolarPeticao/', name: 'Protocolar Petição', Component: ProtocolarPeticao, },
    { path: '/Peticao/PeticoesEnviadas/', name: 'Petições Enviadas', Component: PeticoesEnviadas, },
    { path: '/Peticao/ModelosDeFormularios/', name: 'Modelos de Formulário', Component: ModelosDeFormularios, },
    { path: '/SustentacaoOral/ProtocolarSustentacaoOral/', name: 'Protocolar Sustentação Oral', Component: ProtocolarSustentacao, },
    { path: '/SustentacaoOral/SustentacoesEnviadas/', name: 'Sustentações Orais Enviadas', Component: SustentacoesEnviadas, },
    { path: '/DeclaracaoBensRendas/InformarDeclarantes/', name: 'Informar Declarantes de Bens e Rendas', Component: InformarDeclarantes, },
    { path: '/DeclaracaoBensRendas/GestaoDeclaracoes/', name: 'Gestão de Declarações de Bens e Rendas', Component: GestaoDeclaracoes, },
    { path: '/DeclaracaoBensRendas/GestaoDeclaracoes/:orgaoId/:anoCalendario', name: 'Gestão de Declarações de Bens e Rendas', Component: GestaoDeclaracoes, },
    { path: '/DeclaracaoBensRendas/ProtocolarDeclaracao/', name: 'Protocolar Declaração de Bens e Rendas', Component: ProtocolarDeclaracao, },
    { path: '/DeclaracaoBensRendas/DeclaracoesEnviadas/', name: 'Declarações de Bens e Rendas Enviadas', Component: DeclaracoesEnviadas, },
    { path: '/VistaEletronica/VistasConcedidas/', name: 'Vistas Eletrônicas Concedidas', Component: VistasConcedidas, }
]