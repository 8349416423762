import { Tooltip, Button, CircularProgress } from "@material-ui/core"
import { CloudDownloadOutlined } from "@material-ui/icons"
import { Fragment } from "react"
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Condition from '../../../lab/Condition'

const LoadingButtonDownload = ({ text, textTooltip, disabled, loading, onDownload }) => {

    const themeTooltip = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                    fontWeight: 100
                }
            }
        }
    })

    return (
        <Fragment>
            <Condition assert={textTooltip === undefined}>
                <Button style={{ float: 'right' }} variant="outlined" endIcon={<CloudDownloadOutlined />} color="primary" disabled={disabled} onClick={onDownload}>
                    {text}
                    {loading && <CircularProgress size={24} />}
                </Button>
            </Condition>
            <Condition assert={textTooltip !== undefined}>
                <ThemeProvider theme={themeTooltip}>
                    <Tooltip title={textTooltip}>
                        <Button style={{ float: 'right' }} variant="outlined" endIcon={<CloudDownloadOutlined />} color="primary" disabled={disabled} onClick={onDownload}>
                            {text}
                            {loading && <CircularProgress size={24} />}
                        </Button>
                    </Tooltip>
                </ThemeProvider>
            </Condition>
            <br /><br />
        </Fragment>
    )
}

export { LoadingButtonDownload }