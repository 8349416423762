import { CardActions, CardContent, Divider, FormControl, Grid, Link, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { ViewCreateAnexos } from '../../../components/fragments/Anexos'
import { LoadingButtonNext } from '../../../components/fragments/Buttons/Next'
import { LoadingButtonSend } from '../../../components/fragments/Buttons/Send'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import SelectField from '../../../components/inputs/SelectField'
import Condition from '../../../components/lab/Condition'
import Container from '../../../components/layout/Container'
import { DownloadFile, HandleErrors } from '../../../functions'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'
import UserService from '../../../services/UserService'
import EnumTipoAssuntoPeticao from '../../../enums/enumTipoAssuntoPeticao'
import { validaExtensaoArquivo } from '../../../utils/validaExtensaoArquivo'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    },
    divider: {
        width: '95%',
        margin: 'auto'
    },
    footer: {
        padding: '20px',
    }
})

export default function Home(props) {
    const classes = useStyles()
    const [files, setFiles] = useState([]);
    const [tipoAssunto, setTipoAssunto] = useState('');
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [descricaoAssunto, setDescricaoAssunto] = useState('');
    const [observacao, setObservacao] = useState('');
    const [peticao, setPeticao] = useState();
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const tamanhoMaximoBytesEnvio = 314572800;

    const snackbarVariantInfo = 'info'
    const { enqueueSnackbar } = useSnackbar()

    const limitaCampoNumeroProcesso = (e) => {
        e.target.value = (e.target.value).toString().slice(0, 18);
        setNumeroProcesso(e.target.value);
    }

    const onClickBaixarFormulario = () => {
        setErrors([]);
        setLoading(true);

        ProtocoloDigitalService.PeticoesFormulariosGet(tipoAssunto)
            .then(response => DownloadFile(response.data.tipoArquivo, response.data.nomeArquivo, response.data.conteudo))
            .catch((err) => setErrors([`Falha ao baixar formulário, ${err.response.data.detail}!`]))
            .finally(() => setLoading(false));
    }

    const onNext = () => {
        setErrors([]);
        setLoading(true);
        let _user = UserService.GetData();
        let _descricaoAssunto = '';

        if (tipoAssunto === EnumTipoAssuntoPeticao.Outros) {
            _descricaoAssunto = descricaoAssunto;
        }

        if (numeroProcesso === 'Nan') {
            setNumeroProcesso(null);
        }

        ProtocoloDigitalService.PeticoesPost(_user.cpf, tipoAssunto, _descricaoAssunto, observacao, numeroProcesso)
            .then(response => setPeticao(response.data.find(() => true)))
            .catch(error => setErrors(HandleErrors(error.response.data.detail)))
            .finally(() => setLoading(false));
    }

    const onSend = () => {

        let tamanhoMaximoNomeArquivo = 100
        let listaArquivosValidos = true
        setLoading(true);

        if (!files.length) {
            enqueueSnackbar('É necessário anexar pelo menos um arquivo.', { variant: snackbarVariantInfo })
            setLoading(false);
            return;
        }

        let somaBytesTamanhosArquivos = 0;

        files.forEach(arquivo => {
            if (String(arquivo.name).length > tamanhoMaximoNomeArquivo) {
                enqueueSnackbar(`O nome do arquivo de anexo deve possuir no máximo ${tamanhoMaximoNomeArquivo} caracteres.`, { variant: snackbarVariantInfo })
                listaArquivosValidos = false
            }

            if (!validaExtensaoArquivo(arquivo)) {
                enqueueSnackbar(`O tipo do arquivo de anexo ${arquivo.name} não é permitido.`, { variant: snackbarVariantInfo })
                listaArquivosValidos = false
            }

            somaBytesTamanhosArquivos += arquivo.size;
        });

        if (somaBytesTamanhosArquivos > tamanhoMaximoBytesEnvio) {
            enqueueSnackbar('Tamanho máximo suportado para envio de anexo(s) é de 300MB', { variant: snackbarVariantInfo })
            listaArquivosValidos = false
        }

        if (listaArquivosValidos === false) {
            setLoading(false);
            return;
        }

        let _user = UserService.GetData();
        let _descricaoAssunto = '';

        if (tipoAssunto === EnumTipoAssuntoPeticao.Outros) {
            _descricaoAssunto = descricaoAssunto;
        }

        ProtocoloDigitalService.PeticoesPut(peticao.id, _user.cpf, tipoAssunto, _descricaoAssunto, observacao, numeroProcesso)
            .then(() => ProtocoloDigitalService.AnexosPeticaoUploadPost(peticao.id, files))
            .then(() => ProtocoloDigitalService.PeticoesEnviarPut(peticao.id))
            .then(() => setSuccess('Petição Enviada!'))
            .then(() => setTimeout(() => props.history.push('/Peticao/PeticoesEnviadas'), 2000))
            .catch(error => setErrors(HandleErrors(error.response.data.detail)))
            .finally(() => setLoading(false));
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Protocolar Petição"
        >
            <MessageBox title='Atenção' messages={errors} severity='info' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <SelectField
                                        ide='tipo-assunto'
                                        title='Tipo de Assunto *'
                                        label='Tipo de Assunto'
                                        value={tipoAssunto}
                                        onSelect={item => setTipoAssunto(item)}
                                        items={[
                                            { key: EnumTipoAssuntoPeticao.CertidaoNegativa, name: 'Certidão Negativa' },
                                            { key: EnumTipoAssuntoPeticao.ProrrogacaoPrazo, name: 'Prorrogação de Prazo' },
                                            { key: EnumTipoAssuntoPeticao.VistaEletronica, name: 'Vista Eletrônica' },
                                            { key: EnumTipoAssuntoPeticao.Outros, name: 'Outros' }
                                        ]}
                                    />
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <TextField
                                            label="Nº do Processo (Opcional)"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            variant="outlined"
                                            type="number"
                                            value={numeroProcesso}
                                            onChange={item => limitaCampoNumeroProcesso((item))} />
                                    </FormControl>
                                </Grid>

                                <Condition assert={tipoAssunto}>
                                    {
                                        tipoAssunto === EnumTipoAssuntoPeticao.Outros ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControl>
                                                    <TextField
                                                        label="Descrição do Assunto *"
                                                        variant="outlined"
                                                        value={descricaoAssunto}
                                                        onChange={item => setDescricaoAssunto(item.target.value)} />
                                                </FormControl>
                                            </Grid>
                                            :
                                            tipoAssunto === EnumTipoAssuntoPeticao.CertidaoNegativa ||
                                                tipoAssunto === EnumTipoAssuntoPeticao.ProrrogacaoPrazo ||
                                                tipoAssunto === EnumTipoAssuntoPeticao.VistaEletronica ?
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Link href='#' onClick={onClickBaixarFormulario} underline="always">
                                                        Baixar Modelo de Formulário
                                                    </Link>
                                                </Grid>
                                                :
                                                <Grid></Grid>
                                    }

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl>
                                            <TextField
                                                label="Observações referentes aos documentos anexados para o peticionamento *"
                                                variant="outlined"
                                                minRows={4}
                                                maxRows={8}
                                                multiline
                                                value={observacao}
                                                onChange={item => setObservacao(item.target.value)} />
                                        </FormControl>
                                    </Grid>

                                    <br />
                                    <Condition assert={peticao}>
                                        <Divider className={classes.divider} />
                                        <ViewCreateAnexos
                                            files={files}
                                            setFiles={setFiles}
                                            tamanhoMaximoNomeAnexo={100}
                                        />
                                    </Condition>
                                </Condition>
                            </Grid>
                        </CardContent>

                        <br />
                        <Divider className={classes.divider} />

                        <CardActions className={classes.footer}>
                            <Grid container justifyContent="flex-end">
                                <Condition assert={!peticao}>
                                    <LoadingButtonNext text='Próximo' loading={loading} disabled={!tipoAssunto || loading} onNext={onNext} />
                                </Condition>
                                <Condition assert={peticao}>
                                    <LoadingButtonSend text='Enviar' loading={loading} disabled={!peticao || loading} onSend={onSend} />
                                </Condition>
                            </Grid>
                        </CardActions>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}