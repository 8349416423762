export const validaExtensaoArquivo = (file) => {
    if (file.type.length > 0) {
        var nomeArquivo = String(file.name);
        var extensao = nomeArquivo.toLowerCase().substr(nomeArquivo.lastIndexOf('.') + 1, nomeArquivo.length);
        switch (extensao) {
            case 'avi':
            case 'mov':
            case 'mpeg':
            case 'rmvb':
            case 'mkv':
            case 'aac':
            case 'wma':
            case 'mp4':
            case 'mp3':
            case 'ac3':
            case 'wav':
            case 'ogg':
            case 'm4v':
            case 'txt':
            case 'xls':
            case 'xlsx':
            case 'ofx':
            case 'csv':
            case 'doc':
            case 'docx':
            case 'odt':
            case 'ods':
            case 'odp':
            case 'odg':
            case 'odf':
            case 'pdf':
                return true;
            default:
                return false;
        }
    }
}
