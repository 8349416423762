import { combineReducers } from "redux"

const INITIAL_STATE = {
  openDialogError: false,
  messageDialogError: null
}

export const state = (data = INITIAL_STATE, action) => {
  switch (action.type) {
    case "STATE":
      return action.state;
    case 'OPENDIALOGERROR':
      return { ...data, openDialogError: action.openDialogError }
    case 'MESSAGEDIALOGERROR':
      return { ...data, messageDialogError: action.messageDialogError }
    default:
      return data
  }
}

export const reducers = combineReducers({
  state
})