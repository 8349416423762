import React, { createRef } from 'react'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom'
import App from './App'
import { GlobalStyles } from 'tce-componentes-ui-react'
import GlobalStyle from './styles'
import './assets/index.css'

import { Provider } from 'react-redux'
import { store } from './redux/store'
import DialogErrorFetch from './components/feedback/DialogErrorFetch'

const notistackRef = createRef()
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.render(
  <SnackbarProvider
    ref={notistackRef}
    action={(key) => (
      <Button className="btnCloseSnackBar" onClick={onClickDismiss(key)} component="span">
        <CloseIcon />
      </Button>
    )}
    autoHideDuration={4000}
    maxSnack={10}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <Provider store={store}>
      <GlobalStyles />
      <GlobalStyle />
      <DialogErrorFetch />
      <App />
    </Provider>
  </SnackbarProvider>,
  document.getElementById('root')
)