import ApiProtocoloDigital from '../infra/apiProtocoloDigital'

const ProtocoloDigitalService = {
    PeticoesPost: async (cpf, tipoAssunto, descricaoAssunto, observacao, numeroProcesso) => {
        return await ApiProtocoloDigital.post(`/api/v1/peticoes`, {
            "cpfCnpj": cpf,
            "tipoAssunto": tipoAssunto,
            "descricaoAssunto": descricaoAssunto || null,
            "observacao": observacao,
            "numeroProcesso": numeroProcesso || null
        })
    },
    PeticoesPut: async (id, cpf, tipoAssunto, descricaoAssunto, observacao, numeroProcesso) => {
        return await ApiProtocoloDigital.put(`/api/v1/peticoes`, {
            "id": id,
            "cpfCnpj": cpf,
            "tipoAssunto": tipoAssunto,
            "descricaoAssunto": descricaoAssunto || "",
            "observacao": observacao,
            "numeroProcesso": numeroProcesso || null
        })
    },
    PeticoesGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/peticoes`);
    },
    AnexosPeticaoUploadPost: async (idPeticao, arquivos) => {

        let formData = new FormData();

        arquivos.forEach(arquivo => {
            formData.append(arquivo.name, arquivo);
        });

        return await ApiProtocoloDigital.post(`/api/v1/anexos-peticao/upload/${idPeticao}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
    },
    AnexosPeticaoListagemGet: async (idPeticao) => {
        return await ApiProtocoloDigital.get(`/api/v1/anexos-peticao/listagem/${idPeticao}`);
    },
    AnexosPeticaoDownloadGet: async (idPeticao, idAnexo) => {
        return await ApiProtocoloDigital.get(`/api/v1/anexos-peticao/download/${idPeticao}/${idAnexo}`,
            {
                responseType: 'blob'
            });
    },
    AnexosPeticaoDelete: async (idPeticao, idAnexos) => {
        if (!idAnexos.length)
            return Promise.resolve();

        return await ApiProtocoloDigital.delete(`/api/v1/anexos-peticao/${idPeticao}`, { data: idAnexos });
    },
    PeticoesEnviarPut: async (idPeticao) => {
        return await ApiProtocoloDigital.put(`/api/v1/peticoes/enviar/${idPeticao}`);
    },
    PeticoesFormulariosGet: async (idTipoAssunto) => {
        return await ApiProtocoloDigital.get(`/api/v1/peticoes/formularios/${idTipoAssunto}`);
    },
    SustentacoesOraisUploadPost: async (numeroOficio, anoOficio, arquivos) => {

        let formData = new FormData();

        arquivos.forEach(arquivo => {
            formData.append(arquivo.name, arquivo);
        });

        return await ApiProtocoloDigital.post(`/api/v1/sustentacoes-orais/upload/${numeroOficio}/${anoOficio}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
    },
    SustentacoesOraisConsultaGet: async () => {
        return await ApiProtocoloDigital.get('/api/v1/sustentacoes-orais/consulta');
    },
    SustentacoesOraisConsultaAnexoGet: async (idSustentacaoOral) => {
        return await ApiProtocoloDigital.get(`/api/v1/sustentacoes-orais/consulta/anexo/${idSustentacaoOral}`);
    },
    SustentacoesOraisConsultaAnexoDownloadGet: async (idSustentacaoOral, idAnexo) => {
        return await ApiProtocoloDigital.get(`/api/v1/sustentacoes-orais/consulta/anexo/download/${idSustentacaoOral}/${idAnexo}`,
            {
                responseType: 'blob'
            });
    },
    SustentacoesOraisCancelarPut: async (idSustentacaoOral) => {
        return await ApiProtocoloDigital.put(`/api/v1/sustentacoes-orais/cancelar/${idSustentacaoOral}`);
    },

    DeclaracoesModeloPlanilhaDeclarantesGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/modelo-planilha-declarantes`);
    },
    DeclaracoesRelacaoDeclarantesPost: async (orgaoId, anoCalendario, modalidadeEnvioDBR, arquivosExcel) => {
        let formData = new FormData();

        arquivosExcel.forEach(arquivo => {
            formData.append(arquivo.name, arquivo);
        });

        return await ApiProtocoloDigital.post(`/api/v1/declaracoes/relacao-declarantes/${orgaoId}/${anoCalendario}/${modalidadeEnvioDBR}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
    },
    DeclaracoesRelacaoDeclarantesGet: async (orgaoId, anoCalendario, modalidadeEnvioDBR, situacaoRecebimentoDBR) => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/relacao-declarantes/${orgaoId}/${anoCalendario}/${modalidadeEnvioDBR}/${situacaoRecebimentoDBR}`);
    },
    DeclaracoesRelacaoDeclarantesDownloadGet: async (orgaoId, anoCalendario, modalidadeEnvioDBR, situacaoRecebimentoDBR) => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/relacao-declarantes/download/${orgaoId}/${anoCalendario}/${modalidadeEnvioDBR}/${situacaoRecebimentoDBR}`,
            {
                responseType: 'blob'
            });
    },
    DeclaracoesRelacaoDeclarantesDelete: async (orgaoId, anoCalendario, modalidadeEnvioDBR, cpfDeclarante) => {
        return await ApiProtocoloDigital.delete(`/api/v1/declaracoes/relacao-declarantes/${orgaoId}/${anoCalendario}/${modalidadeEnvioDBR}/${cpfDeclarante}`);
    },
    DeclaracoesAguardandoEnvioGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/aguardando-envio`);
    },
    DeclaracoesOrgaosDeclarantesGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/orgaos-declarantes`);
    },
    DeclaracoesOrgaoLotacaoUsuarioLogadoGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/orgao-lotacao-usuario-logado`);
    },
    DeclaracoesPessoaGet: async (cpf) => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/nome-pessoa/${cpf}`);
    },
    DeclaracoesAnexoInteressadoDownloadGet: async (declaracaoId, interessadoId, anexoId) => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/anexo-interessado/download/${declaracaoId}/${interessadoId}/${anexoId}`,
            {
                responseType: 'blob'
            });
    },
    DeclaracoesAnexoInteressadoRemovaPost: async (declaracaoId, interessadoId, anexoId) => {
        return await ApiProtocoloDigital.post(`/api/v1/declaracoes/anexo-interessado/remova/${declaracaoId}/${interessadoId}/${anexoId}`);
    },
    DeclaracoesCabecalhoPut: async (declaracaoId, anoCalendario, orgaoDeclaranteId) => {
        return await ApiProtocoloDigital.put(`/api/v1/declaracoes/cabecalho/${declaracaoId}/${anoCalendario}/${orgaoDeclaranteId}`);
    },
    DeclaracoesAnexosInteressadoIndividualPost: async (declaracaoId, anoCalendario, orgaoDeclaranteId, interessadoCpf, modalidadeEnvio, arquivos) => {

        let formData = new FormData();

        arquivos.forEach(arquivo => {
            formData.append(arquivo.name, arquivo);
        });

        return await ApiProtocoloDigital.post(`/api/v1/declaracoes/anexos-interessado-individual/${declaracaoId}/${anoCalendario}/${orgaoDeclaranteId}/${interessadoCpf}/${modalidadeEnvio}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
    },
    DeclaracoesAnexosInteressadosColetivoPost: async (declaracaoId, anoCalendario, orgaoDeclaranteId, modalidadeEnvio, arquivos) => {

        let formData = new FormData();

        arquivos.forEach(arquivo => {
            formData.append(arquivo.name, arquivo);
        });

        return await ApiProtocoloDigital.post(`/api/v1/declaracoes/anexos-interessados-coletivo/${declaracaoId}/${anoCalendario}/${orgaoDeclaranteId}/${modalidadeEnvio}`, formData, {
            headers: {
                "Content-type": "multipart/form-data"
            }
        });
    },
    DeclaracoesModalidadeEnvioInteressadoIndividualPost: async (declaracaoId, interessadoId, modalidadeEnvio) => {
        return await ApiProtocoloDigital.post(`/api/v1/declaracoes/modalidade-envio-interessado-individual/${declaracaoId}/${interessadoId}/${modalidadeEnvio}`);
    },
    DeclaracoesEnviaPut: async (declaracaoId) => {
        return await ApiProtocoloDigital.put(`/api/v1/declaracoes/envia/${declaracaoId}`);
    },
    DeclaracoesEnviadasGet: async () => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/enviadas`);
    },
    DeclaracoesReciboEntregaGet: async (declaracaoId) => {
        return await ApiProtocoloDigital.get(`/api/v1/declaracoes/recibo-entrega/${declaracaoId}`);
    },
    VistaEletronicaConsultaGet: async () => {
        return await ApiProtocoloDigital.get('/api/v1/vista-eletronica/consulta');
    },
    VistaEletronicaGeraLinkGet: async (idVista, idAutuacao) => {
        return await ApiProtocoloDigital.get(`/api/v1/vista-eletronica/maven/geralink/${idVista}/${idAutuacao}`);
    }
}

export default ProtocoloDigitalService