import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'
import { DownloadArquivo, HandleErrors } from '../../../functions'
import Container from '../../../components/layout/Container'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import { Grid, Paper, CardContent, FormControl, InputLabel, Select, MenuItem, TextField, Divider, CardActions, Accordion, AccordionSummary, Typography, AccordionDetails, Box, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import { ViewCreateAnexos, ViewListAnexos } from '../../../components/fragments/Anexos'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TablePageable, TableCollapseRow } from '../../../components/fragments/TablesInteressadoDeclaracao'
import { LoadingButtonAttach } from '../../../components/fragments/Buttons/Attach'
import { LoadingButtonCancel } from '../../../components/fragments/Buttons/Cancel'
import { LoadingButtonSend } from '../../../components/fragments/Buttons/Send'
import EnumModalidadeEnvioDBR from '../../../enums/enumModalidadeEnvioDBR'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import DialogConfirmSimple from '../../../components/fragments/DialogConfirmSimple'

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    },
    divider: {
        width: '95%',
        margin: 'auto'
    },
    footer: {
        padding: '20px',
    }
})

export default function ProtocolarDeclaracao(props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [usuarioLogadoGestorRH, setUsuarioLogadoGestorRH] = useState(false)
    const [declaracaoAguardandoEnvio, setDeclaracaoAguardandoEnvio] = useState([])
    const [listaOrgaosDeclarantes, setListaOrgaosDeclarantes] = useState([])
    const [listaInteressados, setListaInteressados] = useState([])
    const [anoCalendario, setAnoCalendario] = useState('')
    const [orgaoDeclarante, setOrgaoDeclarante] = useState({})
    const [interessadoCpf, setInteressadoCpf] = useState('')
    const [modalidadeEnvio, setModalidadeEnvio] = useState(EnumModalidadeEnvioDBR.AtualizacaoAnual)
    const [interessadoNome, setInteressadoNome] = useState('')
    const [success, setSuccess] = useState('')
    const [listaEdicaoAnexosInteressado, setListaEdicaoAnexosInteressado] = useState([])
    const [questionarUsoArquivosInconsistentes, setQuestionarUsoArquivosInconsistentes] = useState(false)

    const tamanhoMaximoBytesEnvio = 314572800
    const tempoPadraoTimeout = 2000

    // Para carregar os dados de uma possível declaração que ainda não tenha sido enviada.
    useEffect(() => {
        const definaDeclaracaoAguardandoEnvio = () => {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesAguardandoEnvioGet()
                .then(response => setDeclaracaoAguardandoEnvio(construaDeclaracao(response.data)))
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
        definaDeclaracaoAguardandoEnvio()

        const construaListaOrgaosDeclarantes = (listaOrgaosDeclarantes) => {
            let _listaOrgaosDeclarantes = []

            if (listaOrgaosDeclarantes !== undefined && listaOrgaosDeclarantes.length > 0) {
                _listaOrgaosDeclarantes = listaOrgaosDeclarantes.map(
                    function (row) {
                        return { code: row.id, label: row.codigo + " - " + row.nome }
                    }
                )
            }

            return _listaOrgaosDeclarantes
        }

        const sugiraOrgao = (orgaoSugerido) => {
            if (orgaoSugerido !== null &&
                orgaoSugerido !== undefined &&
                orgaoSugerido.id !== null &&
                orgaoSugerido.id !== undefined &&
                orgaoSugerido.id !== 0) {
                setOrgaoDeclarante({ code: orgaoSugerido.id, label: orgaoSugerido.codigo + " - " + orgaoSugerido.nome })
            }
        }

        const definaOrgaosDeclarantes = () => {
            ProtocoloDigitalService.DeclaracoesOrgaosDeclarantesGet()
                .then(response => {
                    setListaOrgaosDeclarantes(construaListaOrgaosDeclarantes(response.data))
                    ProtocoloDigitalService.DeclaracoesOrgaoLotacaoUsuarioLogadoGet()
                        .then(response => {
                            sugiraOrgao(response.data)
                        })
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
        }
        definaOrgaosDeclarantes()

        const definaUsuarioLogadoGestorRH = () => {

            // Para verificar se o usuário é autodeclarante ou gestor de RH de órgão jurisdicionado.
            let dadosUsuarioJSON = localStorage.getItem('@tce-protocolodigital-ui-react-data')
            let dadosUsuario = JSON.parse(dadosUsuarioJSON)

            setUsuarioLogadoGestorRH(false)

            if (dadosUsuario.possuiAcessoMenuInformarDeclarantes || dadosUsuario.possuiAcessoMenuGestaoDeclaracoes) {
                setUsuarioLogadoGestorRH(true)
            }
            else {
                let cpf = dadosUsuario.cpf
                cpf = cpf.replace(/\D/g, "")
                cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
                cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
                cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
                setInteressadoCpf(cpf)
                setInteressadoNome(dadosUsuario.nome)
            }
        }
        definaUsuarioLogadoGestorRH()
    }, [])

    const construaDeclaracao = (dadosDeclaracao) => {
        let retorno

        if (dadosDeclaracao.length === 0) {
            retorno = {
                id: 0,
                anoCalendario: 0,
                orgaoDeclaranteId: 0,
                listaInteressados: []
            }
        } else {
            // O sistema permitirá apenas uma declaração com a situação "Aguardando Envio", 
            // sendo assim considera apenas o registro da primeira posição.
            retorno = {
                id: dadosDeclaracao[0].id,
                anoCalendario: dadosDeclaracao[0].anoCalendario,
                orgaoDeclarante: dadosDeclaracao[0].orgaoDeclarante,
                listaInteressados: dadosDeclaracao[0].listaInteressados,
                situacao: dadosDeclaracao[0].situacao,
                dataCadastro: dadosDeclaracao[0].dataCadastro,
                dataRecebimento: dadosDeclaracao[0].dataRecebimento,
                numeroRecibo: dadosDeclaracao[0].numeroRecibo,
                solicitacaoAutuacao: dadosDeclaracao[0].solicitacaoAutuacao
            }
        }
        return retorno
    }

    useEffect(() => {
        if (declaracaoAguardandoEnvio !== undefined &&
            declaracaoAguardandoEnvio.id !== undefined &&
            declaracaoAguardandoEnvio.id !== 0) {
            if (declaracaoAguardandoEnvio.anoCalendario === 0) {
                setAnoCalendario('')
            } else
                setAnoCalendario(declaracaoAguardandoEnvio.anoCalendario.toString())

            setOrgaoDeclarante({ code: declaracaoAguardandoEnvio.orgaoDeclarante.id, label: declaracaoAguardandoEnvio.orgaoDeclarante.codigo + " - " + declaracaoAguardandoEnvio.orgaoDeclarante.nome })
            setListaInteressados(declaracaoAguardandoEnvio.listaInteressados)
        }
    }, [declaracaoAguardandoEnvio])

    const limiteCampoAnoCalendario = (e) => {
        e.currentTarget.maxLength = 4
        let valor = e.currentTarget.value
        e.target.value = valor.replace(/\D/g, "")
        setAnoCalendario(e.target.value)
    }

    const definaNomeInteressado = (cpf) => {
        setErrors([])
        if (cpf !== '') {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesPessoaGet(cpf)
                .then(response => {
                    setInteressadoNome(response.data)
                })
                .catch(error => {
                    setInteressadoNome('')
                    setErrors(HandleErrors(error.response.data.detail))
                })
                .finally(() => setLoading(false))
        }
    }
    const valideCpf = (e) => {
        setErrors([])

        if (e.currentTarget.value.length) {
            let tamanho = e.currentTarget.value.length
            if (tamanho !== undefined && tamanho !== 14) {
                setErrors(['O CPF é inválido, pois deve possuir 11 dígitos.'])
                setInteressadoNome('')
            }
        }
    }

    const definaDadosInteressado = (e) => {
        e.currentTarget.maxLength = 14

        let cpf = e.currentTarget.value

        cpf = cpf.replace(/\D/g, "")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

        if (cpf === '000.000.000-00') {
            cpf = ''
        }

        e.currentTarget.value = cpf
        setInteressadoCpf(e.target.value)

        if (cpf.length === 14) {
            definaNomeInteressado(cpf)
        }
        else {
            setInteressadoNome('')
        }
    }

    const definaModalidadeEnvio = (event) => {
        setModalidadeEnvio(event.target.value)
    }

    const valideExtensaoArquivo = arquivo => {
        if (arquivo.type.length > 0) {
            var nomeArquivo = String(arquivo.name)
            var extensao = nomeArquivo.toLowerCase().substr(nomeArquivo.lastIndexOf('.') + 1, nomeArquivo.length)
            switch (extensao) {
                case 'pdf':
                    return true
                default:
                    return false
            }
        }
    }

    function valideTamanhoNomeArquivo(arquivo, tamanhoMaximoNomeArquivo) {
        if (String(arquivo.name).length > tamanhoMaximoNomeArquivo) {
            return false
        }
        return true
    }

    function valideDadosDeclaracao() {
        setErrors([])

        if (anoCalendario === '' || anoCalendario == null) {
            setErrors(['O ano calendário deve ser informado.'])
            return false
        }

        if (anoCalendario === 0 || anoCalendario === "0") {
            setErrors(['O ano calendário informado é inválido.'])
            return false
        }

        if (anoCalendario >= new Date().getFullYear()) {
            setErrors(['O ano calendário deve ser anterior ao ano atual.'])
            return false
        }

        if (!orgaoDeclarante || !orgaoDeclarante.code || orgaoDeclarante.code === '' || orgaoDeclarante.code === null || orgaoDeclarante.code === 0) {
            setErrors(['O órgão declarante deve ser informado.'])
            return false
        }

        if (interessadoCpf !== undefined && interessadoCpf !== '' && interessadoCpf.length !== 14) {
            setErrors(['O CPF é inválido, pois deve possuir 11 dígitos.'])
            return false
        }

        if (!listaEdicaoAnexosInteressado.length) {
            setErrors(['É necessário anexar pelo menos um arquivo.'])
            return false
        }

        let somaTamanhosArquivos = 0
        listaEdicaoAnexosInteressado.forEach(anexo => {

            let tamanhoMaximoNomeArquivo = 100

            if (!valideExtensaoArquivo(anexo)) {
                setErrors([`O tipo do arquivo ${anexo.name} não é permitido.`])
                return false
            }
            if (!valideTamanhoNomeArquivo(anexo, tamanhoMaximoNomeArquivo)) {
                setErrors([`O nome do arquivo de anexo deve possuir no máximo ${tamanhoMaximoNomeArquivo} caracteres.`])
                return false
            }

            somaTamanhosArquivos = somaTamanhosArquivos + anexo.size
        })

        if (somaTamanhosArquivos > tamanhoMaximoBytesEnvio) {
            setErrors([`Os arquivos não podem possuir mais de 300 MB.`])
            return false
        }

        return true
    }

    const limpeCamposInteressado = () => {
        if (usuarioLogadoGestorRH) {
            setInteressadoCpf('')
            setInteressadoNome('')
        }
        setModalidadeEnvio(EnumModalidadeEnvioDBR.AtualizacaoAnual)
        setListaEdicaoAnexosInteressado([])
    }

    // Para remover os arquivos de DBR em edição.
    const removaArquivosDBR = () => {
        setErrors([])
        setListaEdicaoAnexosInteressado([])
    }

    const realizeAnexacaoArquivos = () => {

        setLoading(true)

        if (interessadoCpf === undefined || interessadoCpf === '') {

            // Neste caso onde não existe CPF do interessado, irá realizar a importação de arquivos de anexos no
            // modo coletivo de interessados, obtendo os diversos CPFs de interessados através dos nomes dos arquivos.
            // Apenas o usuário indetificado como Gestor de RH conseguirá fazer este procedimento (contexto coletivo)
            ProtocoloDigitalService.DeclaracoesAnexosInteressadosColetivoPost(
                declaracaoAguardandoEnvio.id,
                anoCalendario,
                orgaoDeclarante.code,
                modalidadeEnvio,
                listaEdicaoAnexosInteressado)
                .then(response => {
                    let mensagens = []
                    if (Array.isArray(response.data)) {

                        // Lista dos arquivos cujos quais não foi possível extrair o CPF através do nome do arquivo.
                        if (response.data[0].length > 0) {
                            if (response.data[0].length === 1) {
                                mensagens.push("Um arquivo foi ignorado, pois não foi possível extrair o CPF através do nome desse arquivo. Para incluir esse arquivo é necessário preencher o campo 'CPF do Interessado' e anexá-lo novamente.")
                            } else {
                                // Lista dos arquivos cujos quais não foi possível extrair o CPF através do nome do arquivo.
                                mensagens.push("Alguns arquivos foram ignorados, pois não foi possível extrair o CPF através dos nomes desses arquivos. Para incluir esses arquivos é necessário preencher o campo 'CPF do Interessado' e anexá-los novamente.")
                            }
                        }

                        // Lista dos arquivos cujos quais foi possível extrair o CPF, mas o CPF é inválido.
                        if (response.data[1].length > 0) {
                            if (response.data[1].length === 1) {
                                mensagens.push("Um arquivo foi ignorado, pois foi extraído um CPF inválido através do nome desse arquivo. Para incluir esse arquivo é necessário preencher o campo 'CPF do Interessado' e anexá-lo novamente.")
                            } else {
                                mensagens.push("Alguns arquivos foram ignorados, pois foram extraídos CPFs inválidos através dos nomes desses arquivos. Para incluir esses arquivos é necessário preencher o campo 'CPF do Interessado' e anexá-los novamente.")
                            }
                        }
                    }

                    if (mensagens.length > 0) { setErrors(mensagens) }
                    if (listaEdicaoAnexosInteressado.length === 1) { setSuccess('Arquivo anexado.') }
                    if (listaEdicaoAnexosInteressado.length > 1) { setSuccess('Arquivos anexados.') }

                    limpeCamposInteressado()
                    ProtocoloDigitalService.DeclaracoesAguardandoEnvioGet()
                        .then(response => {
                            let _declaracao = construaDeclaracao(response.data)
                            let _listaInteressados = [..._declaracao.listaInteressados]
                            setDeclaracaoAguardandoEnvio(_declaracao)
                            setListaInteressados(_listaInteressados)
                        })
                        .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
        else {

            // Neste caso onde existe o CPF do interessado, irá realizar a importação de todos os arquivos de anexos no
            // modo individual de interessado, isto é, todos os arquivos serão vinculados ao mesmo interessado independente 
            // do nome do arquivo.
            ProtocoloDigitalService.DeclaracoesAnexosInteressadoIndividualPost(
                declaracaoAguardandoEnvio.id,
                anoCalendario,
                orgaoDeclarante.code,
                interessadoCpf,
                modalidadeEnvio,
                listaEdicaoAnexosInteressado)
                .then(response => {

                    if (listaEdicaoAnexosInteressado.length === 1) { setSuccess('Arquivo anexado.') }
                    if (listaEdicaoAnexosInteressado.length > 1) { setSuccess('Arquivos anexados.') }

                    limpeCamposInteressado()
                    ProtocoloDigitalService.DeclaracoesAguardandoEnvioGet()
                        .then(response => {
                            let _declaracao = construaDeclaracao(response.data)
                            let _listaInteressados = [..._declaracao.listaInteressados]
                            setDeclaracaoAguardandoEnvio(_declaracao)
                            setListaInteressados(_listaInteressados)
                        })
                        .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
    }

    // Para anexar os arquivos de DBRs, de acordo com o(s) interessado(s).
    const anexeArquivosDBR = () => {
        setErrors([])

        if (valideDadosDeclaracao()) {
            realizeAnexacaoArquivos()
        }
    }

    function ViewListInteressados({ interessado }) {
        const AltereModalidadeEvioInteressado = (interessado, setSuccessInteresado) => {
            ProtocoloDigitalService.DeclaracoesModalidadeEnvioInteressadoIndividualPost(declaracaoAguardandoEnvio.id, interessado.id, interessado.modalidadeEnvio)
                .then(() => setSuccessInteresado('Modalidade de envio alterada.'))
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
        }

        const realizeDownloadAnexo = (anexo) => {
            ProtocoloDigitalService.DeclaracoesAnexoInteressadoDownloadGet(declaracaoAguardandoEnvio.id, anexo.interessadoId, anexo.id)
                .then(response => DownloadArquivo(response.headers['content-type'], response.headers['content-disposition'], response.data))
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
        }

        function ViewInteressado({ interessado }) {
            const [successAnexo, setSuccessAnexo] = useState('')
            const construaListaAnexosInteressado = (listaAnexosInteressado) => {
                let retorno
                retorno = listaAnexosInteressado.map(anexo => {
                    return {
                        interessadoId: anexo.interessadoId,
                        id: anexo.id,
                        anexoId: anexo.anexoId,
                        name: anexo.name
                    }
                })
                return retorno
            }

            const [listaAnexosInteressado, setListaAnexosInteressado] = useState(construaListaAnexosInteressado(interessado.listaAnexos))

            const excluaAnexo = (anexo) => {
                ProtocoloDigitalService.DeclaracoesAnexoInteressadoRemovaPost(declaracaoAguardandoEnvio.id, anexo.interessadoId, anexo.id)
                    .then(() => {
                        let _listaAnexosInteressado = listaAnexosInteressado.filter((_anexo) => _anexo.id !== anexo.id)
                        interessado.listaAnexos = _listaAnexosInteressado
                        setListaAnexosInteressado(_listaAnexosInteressado)

                        if (!_listaAnexosInteressado.length) {
                            // Necessário para dar tempo da mensagem "Anexo excluído" ser exibida.
                            setTimeout(() => { setListaInteressados(listaInteressados.filter((_interessado) => _interessado.id !== interessado.id)) }, tempoPadraoTimeout);
                        }
                    })
                    .then(() => setSuccessAnexo('Anexo excluído.'))
                    .catch(error => setErrors(HandleErrors(error.response.data.detail)))
            }

            return (
                <Box style={{ padding: '10px' }}>
                    <Snack
                        message={successAnexo}
                        severity='success'
                        onClose={() => setSuccessAnexo(false)} />
                    <ViewListAnexos
                        anexos={listaAnexosInteressado}
                        onClick={(anexo) => realizeDownloadAnexo(anexo)}
                        onRemove={(anexo) => excluaAnexo(anexo)}>
                    </ViewListAnexos>
                </Box>
            )
        }

        const [anexosInteressado, setAnexosInteressado] = useState(interessado.listaAnexos)

        return (
            <TableCollapseRow
                interessado={{ ...interessado, listaInteressados: anexosInteressado }}
                fields={['cpf', 'nome', 'descricaoModalidadeEnvio']}
                onChangeModalidade={(interessado, setSuccessInteresado) => AltereModalidadeEvioInteressado(interessado, setSuccessInteresado)}>
                <ViewInteressado interessado={interessado} setAnexosInteressado={setAnexosInteressado} />
            </TableCollapseRow>
        )
    }

    const TransmitaArquivosDBR = () => {
        setErrors([])

        if (usuarioLogadoGestorRH && interessadoCpf && listaEdicaoAnexosInteressado.length === 0) {
            setErrors(['Existe um interessado em edição para o qual não foram obtidos arquivos. Obtenha os arquivos e os anexe ou limpe os dados do interessado antes de transmitir a DBR.'])
        }
        else if (usuarioLogadoGestorRH && !interessadoCpf && listaEdicaoAnexosInteressado.length === 1) {
            setErrors(['Existe arquivo obtido, mas não foi anexado. Conclua a anexação do arquivo ou remova o arquivo para transmitir a DBR.'])
        }
        else if (usuarioLogadoGestorRH && !interessadoCpf && listaEdicaoAnexosInteressado.length > 1) {
            setErrors(['Existem arquivos obtidos, mas não foram anexados. Conclua a anexação dos arquivos ou remova os arquivos para transmitir a DBR.'])
        }
        else if (usuarioLogadoGestorRH && interessadoCpf && listaEdicaoAnexosInteressado.length === 1) {
            setErrors(['Existe interessado em edição e arquivo obtido que não foi anexado. Conclua a anexação do arquivo para o interessado ou limpe os dados do interessado e remova o arquivo para transmitir a DBR.'])
        }
        else if (usuarioLogadoGestorRH && interessadoCpf && listaEdicaoAnexosInteressado.length > 1) {
            setErrors(['Existe interessado em edição e arquivos obtidos que não foram anexados. Conclua a anexação dos arquivos para o interessado ou limpe os dados do interessado e remova os arquivos para transmitir a DBR.'])
        }
        else if (!usuarioLogadoGestorRH && listaEdicaoAnexosInteressado.length === 1) {
            setErrors(['Existe arquivo obtido, mas não foi anexado. Conclua a anexação do arquivo ou remova o arquivo para transmitir a DBR.'])
        }
        else if (!usuarioLogadoGestorRH && listaEdicaoAnexosInteressado.length > 1) {
            setErrors(['Existem arquivos obtidos, mas não foram anexados. Conclua a anexação dos arquivos ou remova os arquivos para transmitir a DBR.'])
        }
        else {
            ProtocoloDigitalService.DeclaracoesEnviaPut(declaracaoAguardandoEnvio.id)
                .then(() => {
                    setSuccess('Arquivos Transmitidos.')
                    // Necessário para que a mensagem acima seja exibida.
                    setTimeout(() => props.history.push('/DeclaracaoBensRendas/DeclaracoesEnviadas?exibirReciboUltimoEnvio=true'), tempoPadraoTimeout)
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
    }

    const themeTooltip = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                    fontWeight: 100
                }
            }
        }
    })

    const dialogConfirmSimpleOnConfirmPermitirArquivoInconsistente = () => {
        setQuestionarUsoArquivosInconsistentes(false)
        realizeAnexacaoArquivos()
    }

    const dialogConfirmSimpleOnCancelPermitirArquivoInconsistente = () => {
        setQuestionarUsoArquivosInconsistentes(false)
    }

    return (
        <Container
            crumbs={props.crumbs}
            title='Protocolar Declaração de Bens e Rendas'>
            <MessageBox title='Atenção' messages={errors} severity='info' />
            <Snack
                message={success}
                severity='success'
                onClose={() => setSuccess(false)} />
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <FormControl>
                                        <ThemeProvider theme={themeTooltip}>
                                            <Tooltip title="Refere-se ao ano em que ocorreram os fatos geradores da declaração.">
                                                <TextField
                                                    label='Ano Calendário *'
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    variant='outlined'
                                                    type='text'
                                                    value={anoCalendario}
                                                    onChange={item => limiteCampoAnoCalendario(item)} />
                                            </Tooltip>
                                        </ThemeProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Autocomplete
                                        id='orgao-declarante'
                                        options={listaOrgaosDeclarantes}
                                        clearText={'Limpar'}
                                        openText={'Expandir'}
                                        closeText={'Recolher'}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        value={orgaoDeclarante}
                                        getOptionSelected={(option) => option.label}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null &&
                                                newValue.code !== null &&
                                                newValue.code !== undefined) {
                                                setOrgaoDeclarante({ code: newValue.code, label: newValue.label })
                                            }
                                            else {
                                                setOrgaoDeclarante({ code: 0, label: '' })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Órgão Declarante *"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <FormControl>
                                        <TextField
                                            label="CPF do Interessado"
                                            disabled={!usuarioLogadoGestorRH}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            variant="outlined"
                                            type="text"
                                            value={interessadoCpf}
                                            onBlur={item => valideCpf(item)}
                                            onChange={item => definaDadosInteressado(item)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <TextField
                                            label='Nome do Interessado'
                                            disabled={!usuarioLogadoGestorRH}
                                            InputProps={{
                                                inputProps:
                                                {
                                                    readOnly: true
                                                }
                                            }}
                                            variant='outlined'
                                            type='text'
                                            value={interessadoNome} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <FormControl variant='outlined'>
                                        <InputLabel id='select-outlined-label-modalidade-envio'>Modalidade de Envio</InputLabel>
                                        <Select label='Modalidade de Envio'
                                            labelId='select-outlined-label-modalidade-envio'
                                            id='select-outlined-modalidade-envio'
                                            value={modalidadeEnvio}
                                            onChange={definaModalidadeEnvio}
                                        >
                                            <MenuItem value={EnumModalidadeEnvioDBR.AtualizacaoAnual}>Atualização Anual</MenuItem>
                                            <MenuItem value={EnumModalidadeEnvioDBR.EntradaServicoPublico}>Entrada no Serviço Público</MenuItem>
                                            <MenuItem value={EnumModalidadeEnvioDBR.SaidaServicoPublico}>Saída do Serviço Público</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Divider className={classes.divider} />
                                <ViewCreateAnexos
                                    files={listaEdicaoAnexosInteressado}
                                    setFiles={setListaEdicaoAnexosInteressado}
                                    multiple={true}
                                    anexarArquivosDBR={true}
                                    usuarioLogadoGestorRH={usuarioLogadoGestorRH}
                                    tamanhoMaximoNomeAnexo={100}
                                />
                            </Grid>
                            <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*Atenção: Em caso de omissão ou atraso na entrega da declaração, ou de declaração dolosamente inexata, o declarante estará sujeito as medidas previstas no art. 7º da RESOLUÇÃO NORMATIVA Nº 9/2023.</p>
                        </CardContent>
                        <br />
                        <Divider className={classes.divider} />

                        <DialogConfirmSimple
                            openDialog={questionarUsoArquivosInconsistentes}
                            title={'Atenção'}
                            questionText={'O sistema não conseguiu validar um ou mais arquivos para serem anexados, mas você pode prosseguir com o anexo. Conforme o parágrafo único do art. 3 da Resolução 09/2023, somente é aceita a cópia digital em formato PDF gerada diretamente pelo programa de imposto de renda da Secretaria da Receita Federal do Brasil. Arquivos digitalizados ou escaneados não são válidos. Consulte o manual da DBR para mais informações. Deseja prosseguir com o anexo?'}
                            onConfirm={() => dialogConfirmSimpleOnConfirmPermitirArquivoInconsistente()}
                            onCancel={() => dialogConfirmSimpleOnCancelPermitirArquivoInconsistente()} />

                        <Grid container justifyContent='flex-end' spacing={2}>
                            <CardActions className={classes.footer}>
                                <LoadingButtonAttach text='Anexar Arquivos' textTooltip='Anexa os arquivos de DBRs selecionados' loading={loading} disabled={loading} onAttach={anexeArquivosDBR} />
                                <LoadingButtonCancel text='Remover Arquivos' textTooltip='Remove os arquivos de DBRs selecionados' loading={loading} disabled={loading} onCancel={removaArquivosDBR} />
                                <LoadingButtonSend text='Transmitir DBR' textTooltip='Transmite os arquivos de DBRs já anexados' loading={loading} disabled={loading || listaInteressados.length === 0} onSend={TransmitaArquivosDBR} />

                            </CardActions>
                        </Grid>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Interessados na Declaração de Bens e Rendas</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TablePageable
                                                headers={['', 'CPF', 'Nome', 'Modalidade Envio']}
                                                items={listaInteressados}
                                                action={(interessado) => (<ViewListInteressados key={interessado.id} interessado={interessado} />)}
                                            />
                                        </AccordionDetails>

                                        <Divider className={classes.divider} />
                                        <CardActions className={classes.footer}>
                                            <Grid container justifyContent='flex-end'>
                                                <LoadingButtonSend text='Transmitir DBR' textTooltip='Transmite os arquivos de DBRs já anexados' loading={loading} disabled={loading || listaInteressados.length === 0} onSend={TransmitaArquivosDBR} />
                                            </Grid>
                                        </CardActions>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <br />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}