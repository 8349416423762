import Container from '../../components/layout/Container'

export default function Error404(props) {
    return (
        <Container
            crumbs={props.crumbs}
            title="Error404"
        >
        </Container>
    )
}