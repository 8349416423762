import React from "react"
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { Menu } from './styles'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

function SidebarItem({ item, subItem, index }) {
  const classes = useStyles()
  const [collapsed, setCollapsed] = React.useState(false)
  const { descricao, icone, rota, color, subMenus } = item

  function toggleCollapse() {
    setCollapsed((prevValue) => !prevValue)
  }

  function onClick(item) {
    if (Array.isArray(subMenus)) {
      toggleCollapse()
    }
  }

  let expandIcon
  if (Array.isArray(subMenus) && subMenus.length) {
    expandIcon = !collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />
  }

  const verificaAcessoPath = (path) => {
    let data = localStorage.getItem('@tce-protocolodigital-ui-react-data')
    let userData = JSON.parse(data)

    if (path.includes('/DeclaracaoBensRendas/InformarDeclarantes/') && !userData.possuiAcessoMenuInformarDeclarantes) {
      return false
    }

    if (path.includes('/DeclaracaoBensRendas/GestaoDeclaracoes/') && !userData.possuiAcessoMenuGestaoDeclaracoes) {
      return false
    }

    return true
  }

  return (
    <>
      {
        subMenus.length === 0
          ?
          <NavLink activeClassName="active" to={rota}>
            <ListItem className={classes.nested} data-item={item} data-subitem={subItem} data-index={index} >
              <div id="expandIconCollapse" >{expandIcon}</div>
              <ListItemIcon><Menu.IconMenu color={color}>{icone}</Menu.IconMenu></ListItemIcon>
              <ListItemText primary={descricao} />
            </ListItem>
          </NavLink>
          :
          <ListItem onClick={onClick} className={classes.nested} data-item={item} data-subitem={subItem} data-index={index}>
            <div id="expandIconCollapse" >{expandIcon}</div>
            <ListItemIcon><Menu.IconMenu color={color}>{icone}</Menu.IconMenu></ListItemIcon>
            <ListItemText primary={descricao} />
          </ListItem>
      }

      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(subMenus) ? (
          <List disablePadding dense>
            {subMenus.filter(menu => {
              return verificaAcessoPath(menu.rota)
            }).map((subItem, index) => (
              <SidebarItem key={`${subItem.id}${index}`} item={subItem} subItem={subItem} index={index} collapse={false} />
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  )
}

function Sidebar({ subMenus }) {
  const classes = useStyles()

  return (
    <Menu id="Menu">
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">MENU</ListSubheader>
        }
        className={classes.root}
      >
        {subMenus.map((sidebarItem, index) => (
          <SidebarItem
            key={`${sidebarItem.descricao}${index}`}
            item={sidebarItem}
          />
        ))}
      </List>
    </Menu>
  )
}

export default Sidebar