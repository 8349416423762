import moment from 'moment';
import enumSituacaoRecebimentoDBR from '../enums/enumSituacaoRecebimentoDBR';

export const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const DateFormat = (nDate) => {
    let data = new Date(nDate),
        dia = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(),
        mesF = (mes.length === 1) ? '0' + mes : mes,
        anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
}

export const DateTimeFormat = (nDate) => {
    return moment(nDate).toDate()
        .toLocaleDateString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
}

export const CompareDate = (first, second) => {
    return Date.parse(second) - Date.parse(first);
}

export const DownloadFile = (extension, name, content) => {
    let a = document.createElement("a")
    a.href = `data:${extension};base64,${content}`
    a.download = name
    a.click()
}

export const DownloadArquivo = (type, name, content) => {
    const blob = new Blob([content], { type: type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const DownloadLocalFile = (file) => {
    let a = document.createElement("a")
    a.download = file.name;
    a.href = URL.createObjectURL(file);
    a.click();
}

export const HandleErrors = (content) => {
    try {
        let details = JSON.parse(content);
        let errors = details['Validacoes'].map(validations => Object.values(validations).join());
        console.error(errors);
        return errors;
    } catch (e) {
        console.error(content);
        return [content];
    }
}

export const CloneElementByClass = (className) => {
    let elements = document.getElementsByClassName(className);

    if (elements.length > 0) {
        let old_element = elements[0];
        let new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);
    }

}

export const DistinctList = (array, attr) => {
    var resArr = [];
    array.forEach(function (item) {
        var i = resArr.findIndex(x => x[attr] === item[attr]);
        if (i <= -1)
            resArr.push(item);
    });
    return resArr;
}

export const limitCharacters = (text, count, insertDots) => {
    return text ? text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "") : ""
}

export const isNullReturnHyphen = value => value ? value : "-"

export const removeDuplicatesArray = (originalArray, prop) => {
    let newArray = []
    let lookupObject = {}

    for (let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (let i in lookupObject) {
        newArray.push(lookupObject[i])
    }

    return newArray
}

export const validaEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const obtenhaSituacaoRecebimentoDBRPorExtenso = (situacaoRecebimentoDBR) => {

    if (situacaoRecebimentoDBR === enumSituacaoRecebimentoDBR.Indefinida)
        return 'Indefinida'

    else if (situacaoRecebimentoDBR === enumSituacaoRecebimentoDBR.Recebida)
        return 'Recebida'

    else if (situacaoRecebimentoDBR === enumSituacaoRecebimentoDBR.NaoRecebida)
        return 'Não Recebida'

    else
        return ''
}