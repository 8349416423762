import { TextField } from "@material-ui/core";

export default function SearchBar({label, onChange}) {
    return (
        <div style={{display: 'flex'}}>
            <TextField fullWidth 
                type='search'
                variant="outlined"
                onChange={item => onChange(item.target.value)} 
                label={label}/>
        </div>
    );
}