import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CardContent, FormControl, TextField, Divider, Paper, CardActions, LinearProgress, InputLabel, Select, MenuItem, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import { TablePageable } from '../../../components/fragments/Tables'
import Container from '../../../components/layout/Container'
import { HandleErrors, DownloadArquivo, obtenhaSituacaoRecebimentoDBRPorExtenso } from '../../../functions'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'
import { LoadingButtonSearch } from '../../../components/fragments/Buttons/Search'
import { LoadingButtonDownload } from '../../../components/fragments/Buttons/Download'
import enumModalidadeEnvioDBR from '../../../enums/enumModalidadeEnvioDBR'
import enumSituacaoRecebimentoDBR from '../../../enums/enumSituacaoRecebimentoDBR'
import { TableActionDeleteRow } from '../../../components/fragments/TablesActions'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    },
    divider: {
        width: '95%',
        margin: 'auto'
    },
    footer: {
        padding: '20px',
    }
})

export default function GestaoDeclaracoes(props) {
    const [parametrosURL] = useState(props.match.params)
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState('')
    const [errors, setErrors] = useState([])
    const [listaOrgaosDeclarantes, setListaOrgaosDeclarantes] = useState([])
    const [anoCalendarioConsultar, setAnoCalendarioConsultar] = useState('')
    const [orgaoDeclaranteConsultar, setOrgaoDeclaranteConsultar] = useState({})
    const [situacaoRecebimentoDBRConsultar, setSituacaoRecebimentoDBRConsultar] = useState(enumSituacaoRecebimentoDBR.Indefinida)
    const [listaDeclarantesConsultados, setListaDeclarantesConsultados] = useState([])

    // Para carregar os dados dos órgãos.
    useEffect(() => {
        let _orgaoDeclaranteConsultar
        let _anoCalendarioConsultar

        const construaListaOrgaosDeclarantes = (listaOrgaosDeclarantes) => {
            let _listaOrgaosDeclarantes = []

            if (listaOrgaosDeclarantes !== undefined && listaOrgaosDeclarantes.length > 0) {
                _listaOrgaosDeclarantes = listaOrgaosDeclarantes.map(
                    function (row) {
                        return { code: row.id, label: row.codigo + " - " + row.nome }
                    }
                )
            }

            return _listaOrgaosDeclarantes
        }

        const construaOrgao = (orgaoSugerido) => {
            if (orgaoSugerido !== null &&
                orgaoSugerido !== undefined &&
                orgaoSugerido.id !== null &&
                orgaoSugerido.id !== undefined &&
                orgaoSugerido.id !== 0) {
                return { code: orgaoSugerido.id, label: orgaoSugerido.codigo + " - " + orgaoSugerido.nome }
            }
        }

        const procureDeclarantesUseEffect = (codigoOrgao, anoCalendario) => {
            setErrors([])
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesGet(codigoOrgao, anoCalendario, enumModalidadeEnvioDBR.AtualizacaoAnual, enumSituacaoRecebimentoDBR.Indefinida)
                .then(response => {
                    let _calendarioDeclarantes = construaCalendarioDeclarantes(response.data)
                    let _listaDeclarantes = [..._calendarioDeclarantes.listaDeclarantes]
                    setListaDeclarantesConsultados(_listaDeclarantes)
                })

                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }

        const definaOrgaosDeclarantes = () => {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesOrgaosDeclarantesGet()
                .then(response => {
                    let _listaOrgaosDeclarantes = construaListaOrgaosDeclarantes(response.data)
                    setListaOrgaosDeclarantes(_listaOrgaosDeclarantes)

                    if (parametrosURL !== undefined &&
                        parametrosURL.orgaoId !== undefined &&
                        parametrosURL.orgaoId !== 0) {

                        // No caso de existir código de órgão declarante na URL
                        let _orgaoDeclaranteParametroURL = _listaOrgaosDeclarantes.filter((orgao) => orgao.code === parseInt(parametrosURL.orgaoId))
                        _orgaoDeclaranteConsultar = _orgaoDeclaranteParametroURL[0]

                        setOrgaoDeclaranteConsultar(_orgaoDeclaranteConsultar)

                        ProtocoloDigitalService.DeclaracoesOrgaoLotacaoUsuarioLogadoGet()
                            .then(response => {
                                _orgaoDeclaranteConsultar = construaOrgao(response.data)
                            })
                    }
                    else {
                        // No caso de não existir órgão declarante na URL, considera o órgão de lotação do usuário como sugestão.
                        ProtocoloDigitalService.DeclaracoesOrgaoLotacaoUsuarioLogadoGet()
                            .then(response => {
                                _orgaoDeclaranteConsultar = construaOrgao(response.data)
                                setOrgaoDeclaranteConsultar(_orgaoDeclaranteConsultar)
                            })
                    }
                    if (parametrosURL.anoCalendario !== undefined && parseInt(parametrosURL.anoCalendario) !== 0) {
                        _anoCalendarioConsultar = parametrosURL.anoCalendario.toString()
                        setAnoCalendarioConsultar(_anoCalendarioConsultar)
                    }

                    if (_orgaoDeclaranteConsultar !== undefined && _orgaoDeclaranteConsultar.code > 0 &&
                        _anoCalendarioConsultar !== undefined && parseInt(_anoCalendarioConsultar) > 0) {
                        procureDeclarantesUseEffect(_orgaoDeclaranteConsultar.code, _anoCalendarioConsultar)
                    }
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
        definaOrgaosDeclarantes()
    }, [parametrosURL])

    const limiteCampoAnoCalendario = (event) => {
        event.currentTarget.maxLength = 4
        let valor = event.currentTarget.value
        event.target.value = valor.replace(/\D/g, "")
        setAnoCalendarioConsultar(event.target.value)
    }

    function ValideOrgaoDeclaranteInformado(orgaoDeclaranteConsultar) {
        if (!orgaoDeclaranteConsultar || !orgaoDeclaranteConsultar.code || orgaoDeclaranteConsultar.code === '' || orgaoDeclaranteConsultar.code === null || orgaoDeclaranteConsultar.code === 0) {
            return false
        }
        return true;
    }

    function ValideAnoCaledarioInformado(anoCalendarioConsultar) {
        if (anoCalendarioConsultar === '' || anoCalendarioConsultar == null) {
            return false
        }
        return true;
    }

    function ValideAnoCaledarioValido(anoCalendarioConsultar) {
        if (anoCalendarioConsultar === 0 || anoCalendarioConsultar === "0" || anoCalendarioConsultar.length < 4) {
            return false
        }
        return true;
    }

    function ValideAnoCaledarioAnteriorAnoAtual(anoCalendarioConsultar) {
        if (anoCalendarioConsultar >= new Date().getFullYear()) {
            return false
        }
        return true;
    }

    function valideDadosConsultaDeclarantes() {
        setErrors([])

        let retorno = true

        if (!ValideOrgaoDeclaranteInformado(orgaoDeclaranteConsultar)) {
            setErrors(['O órgão dos declarantes deve ser informado.'])
            retorno = false
        }

        if (!ValideAnoCaledarioInformado(anoCalendarioConsultar)) {
            setErrors(['O ano calendário deve ser informado.'])
            retorno = false
        }

        if (!ValideAnoCaledarioValido(anoCalendarioConsultar)) {
            setErrors(['O ano calendário informado é inválido.'])
            retorno = false
        }

        if (!ValideAnoCaledarioAnteriorAnoAtual(anoCalendarioConsultar)) {
            setErrors(['O ano calendário deve ser anterior ao ano atual.'])
            retorno = false
        }

        return retorno
    }

    const construaCalendarioDeclarantes = (calendarioDeclarantes) => {
        let retorno

        if (calendarioDeclarantes === undefined) {
            retorno = {
                calendarioId: 0,
                orgaoId: 0,
                orgaoCodigo: '',
                orgaoNome: '',
                ano: 0,
                modalidadeEnvio: 0,
                descricaoModalidadeEnvio: '',
                listaDeclarantes: []
            }
        } else {
            retorno = {
                calendarioId: calendarioDeclarantes.calendarioId,
                orgaoId: calendarioDeclarantes.orgaoId,
                orgaoCodigo: calendarioDeclarantes.orgaoCodigo,
                orgaoNome: calendarioDeclarantes.orgaoNome,
                ano: calendarioDeclarantes.ano,
                modalidadeEnvio: calendarioDeclarantes.modalidadeEnvio,
                descricaoModalidadeEnvio: calendarioDeclarantes.descricaoModalidadeEnvio,
                listaDeclarantes: calendarioDeclarantes.listaDeclarantes
            }
        }
        return retorno
    }

    const onChangeSelectsituacaoRecebimentoDBR = (event) => {
        setSituacaoRecebimentoDBRConsultar(event.target.value)
    }

    // Para baixar a relação de declarantes para o ano calendário e órgão informados.
    const baixeRelacaoDeclarantes = () => {
        setErrors([])
        if (valideDadosConsultaDeclarantes()) {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesDownloadGet(
                orgaoDeclaranteConsultar.code,
                anoCalendarioConsultar,
                enumModalidadeEnvioDBR.AtualizacaoAnual,
                situacaoRecebimentoDBRConsultar)
                .then(response => {
                    DownloadArquivo(response.headers['content-type'], 'Relação de Declarantes.xlsx', response.data)
                })
                .catch((err) => setErrors([`Falha ao baixar relatório de declarantes!`]))
                .finally(() => setLoading(false));
        }
    }

    const procureDeclarantesEvent = (codigoOrgao, anoCalendario, situacaoRecebimentoDBR) => {
        setErrors([])
        setLoading(true)
        ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesGet(codigoOrgao, anoCalendario, enumModalidadeEnvioDBR.AtualizacaoAnual, situacaoRecebimentoDBR)
            .then(response => {
                let _calendarioDeclarantes = construaCalendarioDeclarantes(response.data)
                let _listaDeclarantes = [..._calendarioDeclarantes.listaDeclarantes]
                setListaDeclarantesConsultados(_listaDeclarantes)
            })

            .catch(error => setErrors(HandleErrors(error.response.data.detail)))
            .finally(() => setLoading(false))
    }

    // Para procurar os declarantes para o ano calendário, órgão informados e situação de recebimento informados.
    const procureDeclarantes = () => {
        setErrors([])
        if (valideDadosConsultaDeclarantes()) {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesGet(orgaoDeclaranteConsultar.code, anoCalendarioConsultar, enumModalidadeEnvioDBR.AtualizacaoAnual, situacaoRecebimentoDBRConsultar)
                .then(response => {
                    let _calendarioDeclarantes = construaCalendarioDeclarantes(response.data)
                    let _listaDeclarantes = [..._calendarioDeclarantes.listaDeclarantes]
                    setListaDeclarantesConsultados(_listaDeclarantes)
                })

                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
    }

    function DeclaranteView({ declarante }) {

        const removaDeclarante = (declarante) => {
            setLoading(true);
            ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesDelete(orgaoDeclaranteConsultar.code, anoCalendarioConsultar, enumModalidadeEnvioDBR.AtualizacaoAnual, declarante.cpf)
                .then(() => {
                    setSuccess('Declarante removido.')
                    ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesGet(orgaoDeclaranteConsultar.code, anoCalendarioConsultar, enumModalidadeEnvioDBR.AtualizacaoAnual, situacaoRecebimentoDBRConsultar)
                        .then(response => {
                            let _calendarioDeclarantes = construaCalendarioDeclarantes(response.data)
                            let _listaDeclarantes = [..._calendarioDeclarantes.listaDeclarantes]
                            setListaDeclarantesConsultados(_listaDeclarantes)
                        })

                        .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
        return (
            <TableActionDeleteRow
                titleIconButton={'Remover'}
                fields={['cpfFormatado', 'nome', 'descricaoSituacaoRecebimentoDBR']}
                item={declarante}
                onDelete={() => removaDeclarante(declarante)}
                tableCellPaddingTop={'4px'}
                tableCellPaddingBottom={'4px'}
            >
            </TableActionDeleteRow>
        );
    }

    const themeTooltip = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                    fontWeight: 100
                }
            }
        }
    })

    return (
        <Container
            crumbs={props.crumbs}
            title="Gestão de Declarações de Bens e Rendas">
            <MessageBox title='Atenção' messages={errors} severity='info' />
            <Snack
                message={success}
                severity='success'
                onClose={() => setSuccess(false)} />
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Autocomplete
                                        id='orgao-declarante'
                                        options={listaOrgaosDeclarantes}
                                        clearText={'Limpar'}
                                        openText={'Expandir'}
                                        closeText={'Recolher'}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        value={orgaoDeclaranteConsultar}
                                        getOptionSelected={(option) => option.label}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null &&
                                                newValue.code !== null &&
                                                newValue.code !== undefined) {
                                                let _orgaoDeclarante = { code: newValue.code, label: newValue.label }
                                                setOrgaoDeclaranteConsultar(_orgaoDeclarante)

                                                if (ValideAnoCaledarioInformado(anoCalendarioConsultar) &&
                                                    ValideAnoCaledarioValido(anoCalendarioConsultar) &&
                                                    ValideAnoCaledarioAnteriorAnoAtual(anoCalendarioConsultar)) {
                                                    procureDeclarantesEvent(_orgaoDeclarante.code, anoCalendarioConsultar, situacaoRecebimentoDBRConsultar)
                                                }
                                            }
                                            else {
                                                setOrgaoDeclaranteConsultar({ code: 0, label: '' })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Órgão Declarante *"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <FormControl>
                                        <ThemeProvider theme={themeTooltip}>
                                            <Tooltip title="Refere-se ao ano em que ocorreram os fatos geradores da declaração.">
                                                <TextField
                                                    label='Ano Calendário *'
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    variant='outlined'
                                                    type='text'
                                                    value={anoCalendarioConsultar}
                                                    onChange={event => limiteCampoAnoCalendario(event)}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter' &&
                                                            ValideOrgaoDeclaranteInformado(orgaoDeclaranteConsultar) &&
                                                            ValideAnoCaledarioInformado(event.target.value) &&
                                                            ValideAnoCaledarioValido(event.target.value) &&
                                                            ValideAnoCaledarioAnteriorAnoAtual(event.target.value)) {
                                                            procureDeclarantesEvent(orgaoDeclaranteConsultar.code, event.target.value, situacaoRecebimentoDBRConsultar)
                                                        }
                                                    }}
                                                    onBlur={(event) => {
                                                        if (ValideOrgaoDeclaranteInformado(orgaoDeclaranteConsultar) &&
                                                            ValideAnoCaledarioInformado(event.target.value) &&
                                                            ValideAnoCaledarioValido(event.target.value) &&
                                                            ValideAnoCaledarioAnteriorAnoAtual(event.target.value)) {
                                                            procureDeclarantesEvent(orgaoDeclaranteConsultar.code, event.target.value, situacaoRecebimentoDBRConsultar)
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </ThemeProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                    <FormControl variant='outlined'>
                                        <InputLabel id='alterar-select-outlined-label-situacao-recebimento'>
                                            Situação
                                        </InputLabel>
                                        <Select
                                            label='Situação'
                                            labelId='alterar-select-outlined-label-situacao-recebimento'
                                            id='alterar-select-outlined-situacao-recebimento'
                                            value={situacaoRecebimentoDBRConsultar}
                                            onChange={event => {
                                                onChangeSelectsituacaoRecebimentoDBR(event)
                                                if (ValideOrgaoDeclaranteInformado(orgaoDeclaranteConsultar) &&
                                                    ValideAnoCaledarioInformado(anoCalendarioConsultar) &&
                                                    ValideAnoCaledarioValido(anoCalendarioConsultar) &&
                                                    ValideAnoCaledarioAnteriorAnoAtual()) {
                                                    procureDeclarantesEvent(orgaoDeclaranteConsultar.code, anoCalendarioConsultar, event.target.value)
                                                }
                                            }}
                                        >
                                            <MenuItem value={enumSituacaoRecebimentoDBR.Indefinida}>Todas as situações</MenuItem>
                                            <MenuItem value={enumSituacaoRecebimentoDBR.Recebida}>{obtenhaSituacaoRecebimentoDBRPorExtenso(enumSituacaoRecebimentoDBR.Recebida)}</MenuItem>
                                            <MenuItem value={enumSituacaoRecebimentoDBR.NaoRecebida}>{obtenhaSituacaoRecebimentoDBRPorExtenso(enumSituacaoRecebimentoDBR.NaoRecebida)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='flex-end'>
                                <CardActions className={classes.footer}>
                                    <Grid container justifyContent='flex-end'>
                                        <LoadingButtonDownload text='Download' loading={loading} disabled={loading} onDownload={baixeRelacaoDeclarantes} />
                                    </Grid>
                                    <Grid container justifyContent='flex-end'>
                                        <LoadingButtonSearch text='Procurar' loading={loading} disabled={loading} onSearch={procureDeclarantes} />
                                    </Grid>
                                </CardActions>
                            </Grid>
                        </CardContent>
                        <br />
                        <Divider className={classes.divider} />
                    </Paper>
                </Grid>
            </Grid>

            <br /><Divider />
            <LinearProgress hidden={!loading} />
            <TablePageable
                headers={['Ação', 'CPF', 'Nome', 'Situação']}
                items={listaDeclarantesConsultados}
                action={(declarante) => (<DeclaranteView key={declarante.id} declarante={declarante} />)}
                rowsPerPageDefault={15}
                rowsPerPageOptions={[15, 100, 250, 500]}
                onTopTablePagination={true}
            />
        </Container>
    )
}