import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

import { isAuthenticated } from "./auth"
import routes from './routes'

import Error404 from './pages/Error404'

import Login from './pages/Login/'
import LogoImg from './assets/img/logo-sistema.svg'

import webConfig from './webconfig.json'

export default function Routes() {
  const LoginRoute = () => <Login
    nomeProjeto="Protocolo Eletrônico"
    urlLogin={webConfig.ApiLogin}
    imgLogo={LogoImg}
    nomeSistema={webConfig.nomeSistema}
    emailContato={webConfig.EmailContato}
  />

  const verificaAcessoPath = (path) => {
    let data = localStorage.getItem('@tce-protocolodigital-ui-react-data')
    let userData = JSON.parse(data)

    if (path.includes('/DeclaracaoBensRendas/InformarDeclarantes') && !userData.possuiAcessoMenuInformarDeclarantes) {
      return false
    }

    if (path.includes('/DeclaracaoBensRendas/GestaoDeclaracoes') && !userData.possuiAcessoMenuGestaoDeclaracoes) {
      return false
    }

    return true
  }

  return (
    <BrowserRouter>
      <Switch>
        {
          routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = routes
                  .filter(({ path }) => props.match.path.includes(path) && verificaAcessoPath(path))
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ?
                      Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(`:${param}`, props.match.params[param]),
                        path
                      )
                      :
                      path,
                    ...rest
                  }))
                return (
                  isAuthenticated()
                    ?
                    (
                      verificaAcessoPath(props.match.path)
                        ?
                        (
                          <Component {...props} crumbs={crumbs} />
                        )
                        :
                        (
                          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                        )
                    )
                    :
                    (
                      <Redirect to={{ pathname: "/Login", state: { from: props.location } }} />
                    )
                )
              }}
            />
          ))
        }
        <Route exact path="/Login" component={LoginRoute} />
        <Route path="*" component={Error404} />
      </Switch>
    </BrowserRouter>
  )
}