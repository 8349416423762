import React from 'react'
import { Button } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CardCustom } from "./styles";
import { Link } from 'react-router-dom';

export default function Card({
    image,
    title,
    description,
    link,
    additionalInfo,
    children
}) {    
    return (
        <CardCustom id="CardCustom">
            <CardCustom.Container id="CardCustom_Container">
                {
                    (title && description) && <CardCustom.Info id="CardCustom_Info">
                        <CardCustom.ImageBox id="CardCustom_ImageBox"><CardCustom.Image id="CardCustom_Image" src={image} /></CardCustom.ImageBox>
                        <CardCustom.InfoBox id="CardCustom_InfoBox">
                            <CardCustom.Title id="CardCustom_Title">{title}</CardCustom.Title>
                            <CardCustom.Description id="CardCustom_Description">{description}</CardCustom.Description>
                        </CardCustom.InfoBox>
                    </CardCustom.Info>
                }
                {
                    additionalInfo && <CardCustom.AdditionalInfo id="CardCustom_AdditionalInfo">
                        {additionalInfo}
                    </CardCustom.AdditionalInfo>
                }
                {
                    link && <Link to={link}>
                        <Button id="btn-acess-link">Acessar <ChevronRightIcon /></Button>
                    </Link>
                }

                {children && <CardCustom.Children id="CardCustom_Children">{children}</CardCustom.Children>}
            </CardCustom.Container>
        </CardCustom>
    )
}