import React, { useLayoutEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { LinearProgress } from 'tce-componentes-ui-react';

import { ReactComponent as GovBRLogo } from '../../assets/img/govbr-logo.svg';
import { Card, CardContent, Container, Copyright, Description, LoadingArea, Logo, Symbol, Title } from './styles';

import axios from 'axios';


export default function Login({
    urlLogin,
    nomeSistema,
    imgLogo,
    nomeProjeto,
    emailContato
}) {
    const iGovBrURL = `${urlLogin}/api/v1/AutenticacaoGovSso/login?redirect=${window.location.origin}/login`
    const aplicacaoProtocoloDigital = 387

    const [logado, setLogado] = useState(false)
    const [loading, setLoading] = useState(false)

    const [errorLogin, setErrorLogin] = useState(false)
    const [errorLoginHelper, setErrorLoginHelper] = useState('')

    const anoCorrente = new Date().getFullYear()

    useLayoutEffect(() => {
        function handleError(message) {
            setLoading(false);
            setErrorLogin(true);
            setErrorLoginHelper(`Erro, tente novamente ou entre em contato com ${emailContato} caso o erro persista`);
            console.error(message);
        }

        async function handleSubmit(token, user) {
            setLoading(true);
            const config = {
                headers: { "authorization": `Bearer ${token}` }

            }
            await axios.get(`${urlLogin}/api/v1/AutenticacaoGovSso/me?usuario=${user}&aplicacao=${aplicacaoProtocoloDigital}`, config)
                .then(function (response) {
                    localStorage.setItem(`@${nomeSistema}`, response.data.accessToken)
                    localStorage.setItem(`@${nomeSistema}-data`, JSON.stringify({
                        id: response.data.id,
                        nome: response.data.nome,
                        email: response.data.email,
                        usuario: response.data.email,
                        cpf: response.data.cpf,
                        possuiAcessoMenuInformarDeclarantes: response.data.menusAplicacaoAcessada.some(permissoes => permissoes.includes('mnuEnvioDeclarantesDBR')),
                        possuiAcessoMenuGestaoDeclaracoes: response.data.menusAplicacaoAcessada.some(permissoes => permissoes.includes('mnuGestaoDeclarantesDBR')),
                    }))

                    //Remove o login e rediciona para a home page
                    setLoading(false)
                    setLogado(true)
                })
                .catch(function (error) {
                    handleError(error);
                })
        }

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');
        let user = params.get('id');

        if (token && user) {
            handleSubmit(token, user);
            return;
        }

        if (params.has('error'))
            handleError(params.get('error'));
    }, [emailContato, nomeSistema, urlLogin])

    return (
        <Container>
            <Card>

                {
                    logado ? <Redirect to="/Home" /> : null
                }

                {
                    loading
                        ?
                        <LoadingArea>
                            <LinearProgress />
                        </LoadingArea>
                        :
                        null
                }

                <CardContent>
                    <Logo src={imgLogo} />
                    <Title>{nomeProjeto}</Title>
                    <Description>Tribunal de Contas do Estado de Goiás</Description>
                </CardContent>

                <CardContent.GovBR id="CardContent_GovBR">
                    <Button variant="contained" className="button-login-govbr" href={iGovBrURL}>
                        <GovBRLogo />
                        <div className="separator-login-govbr"></div>
                        Entrar com gov.br
                    </Button>
                </CardContent.GovBR>

                {
                    errorLogin ?
                        <CardContent>
                            <Description className="errorLoginHelper">
                                {errorLoginHelper}
                            </Description>
                        </CardContent>
                        : null
                }

                <Copyright>
                    {anoCorrente} <Symbol>©</Symbol> Tribunal de Contas do Estado de Goiás
                </Copyright>
            </Card>
        </Container>
    )
}