import { useEffect } from 'react'
import { Breadcrumbs, ContainerPage, Title } from 'tce-componentes-ui-react'
import { validaToken } from '../../../auth'
//import { subMenus } from '../../navigation/Menu'
import LogoProtocoloEletronico from '../../../assets/img/logo-protocolo-eletronico.svg'
import MenuDrawer from '../../navigation/Menu/menuDrawer'
import Navigator from '../Navigator'
import webConfig from '../../../webconfig.json'
import useMenus from '../../../hooks/useMenus'

export default function Container(props) {
    useEffect(() => {
        validaToken();
    }, []);

    const { subMenus } = useMenus()

    return (
        <ContainerPage id="ContainerPage">
            <Navigator
                logoSistema={LogoProtocoloEletronico}
                nomeSistema={webConfig.nomeSistema}
                ImgLogoTCEGO={LogoProtocoloEletronico}
                dataMenus={subMenus}
            />
            <ContainerPage.Content id="ContainerPage_Content">
                <ContainerPage.Menu id="ContainerPage_Menu">
                    <MenuDrawer subMenus={subMenus} />
                </ContainerPage.Menu>
                <ContainerPage.ContentPage id="ContainerPage_ContentPage">
                    <Breadcrumbs crumbs={props.crumbs} />
                    <Title>{props.title}</Title>
                    <Title.Description>{props.description}</Title.Description>
                    {props.children}
                </ContainerPage.ContentPage>
            </ContainerPage.Content>
        </ContainerPage>
    )
}