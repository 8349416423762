import axios from "axios"
import webconfig from '../webconfig.json'
import { validaToken, getToken } from "../auth"

const ApiProtocoloDigital = axios.create({
  baseURL: webconfig.ApiProtocoloDigital,
})

ApiProtocoloDigital.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = token
    validaToken()
  }
  return config
})

export default ApiProtocoloDigital