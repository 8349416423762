// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Indefinida: 0,
    Cadastrada: 1,
    Modificada: 2,
    Enviada: 3,
    Reenviada: 4,
    Rejeitada: 5,
    Autuada: 6,
    Protocolada: 7,
    ProtocoladaAutuada: 8,
    Cancelada: 9
}