import { useEffect, useState } from "react"
import webConfig from '../webconfig.json'
import { HomeOutlined, DescriptionOutlined, SendOutlined, AssignmentOutlined, VideocamOutlined, VisibilityOutlined, SwitchVideoOutlined, AccountCircleOutlined, AllInboxOutlined } from '@material-ui/icons'

const useMenus = () => {
    const [usuarioLogadoEhGestorRH, setUsuarioLogadoEhGestorRH] = useState(false)

    const subMenus = [
        {
            descricao: "Início",
            rota: "/Home",
            icone: <HomeOutlined />,
            color: '#3880e6',
            subMenus: []
        },
        {
            descricao: "Petição",
            rota: "/Peticao/",
            icone: <DescriptionOutlined />,
            color: '#545c63',
            subMenus: [
                {
                    descricao: "Protocolar Petição",
                    rota: "/Peticao/ProtocolarPeticao",
                    icone: <DescriptionOutlined />,
                    color: '#545c63',
                    subMenus: []
                },
                {
                    descricao: "Petições Enviadas",
                    rota: "/Peticao/PeticoesEnviadas",
                    icone: <SendOutlined />,
                    color: '#545c63',
                    subMenus: []
                },
                {
                    descricao: "Modelos de Formulários",
                    rota: "/Peticao/ModelosDeFormularios",
                    icone: <AssignmentOutlined />,
                    color: '#545c63',
                    subMenus: []
                }
            ]
        },
        {
            descricao: "Sustentação Oral",
            rota: "/SustentacaoOral/",
            icone: <VideocamOutlined />,
            color: '#f13939',
            subMenus: [
                {
                    descricao: "Protocolar Sustentação Oral",
                    rota: "/SustentacaoOral/ProtocolarSustentacaoOral",
                    icone: <VideocamOutlined />,
                    color: '#f13939',
                    subMenus: []
                },
                {
                    descricao: "Sustentações Orais Enviadas",
                    rota: "/SustentacaoOral/SustentacoesEnviadas",
                    icone: <SwitchVideoOutlined />,
                    color: '#f13939',
                    subMenus: []
                }
            ]
        },
        {
            descricao: "Declaração de Bens e Rendas",
            rota: "/DeclaracaoBensRendas/",
            icone: <AccountCircleOutlined />,
            color: '#16d365',
            subMenus: [
                ...usuarioLogadoEhGestorRH ? [
                    {
                        descricao: "Informar Declarantes de Bens e Rendas",
                        rota: "/DeclaracaoBensRendas/InformarDeclarantes",
                        icone: <AccountCircleOutlined />,
                        color: '#16d365',
                        subMenus: []
                    }] : [],
                ...usuarioLogadoEhGestorRH ? [
                    {
                        descricao: "Gestão de Declarações de Bens e Rendas",
                        rota: "/DeclaracaoBensRendas/GestaoDeclaracoes",
                        icone: <AllInboxOutlined />,
                        color: '#16d365',
                        subMenus: []
                    }] : [],
                {
                    descricao: "Protocolar Declaração de Bens e Rendas",
                    rota: "/DeclaracaoBensRendas/ProtocolarDeclaracao",
                    icone: <DescriptionOutlined />,
                    color: '#16d365',
                    subMenus: []
                },
                {
                    descricao: "Declarações de Bens e Rendas Enviadas",
                    rota: "/DeclaracaoBensRendas/DeclaracoesEnviadas",
                    icone: <SendOutlined />,
                    color: '#16d365 ',
                    subMenus: []
                }
            ]
        },
        {
            descricao: "Vistas Eletrônicas Concedidas",
            rota: "/VistaEletronica/VistasConcedidas",
            icone: <VisibilityOutlined />,
            color: '#d39716 ',
            subMenus: []
        }
    ];

    const definaUsuarioLogadoGestorRH = () => {
        const dadosUsuarioJSON = localStorage.getItem(`@${webConfig.nomeSistema}-data`)
        let dadosUsuario = JSON.parse(dadosUsuarioJSON)
        setUsuarioLogadoEhGestorRH(dadosUsuario.possuiAcessoMenuInformarDeclarantes || dadosUsuario.possuiAcessoMenuGestaoDeclaracoes)
    }

    useEffect(() => {
        definaUsuarioLogadoGestorRH()
    }, [])

    return {
        subMenus
    }
}

export default useMenus