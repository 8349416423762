import React from 'react'
import { Fragment, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Slide from '@material-ui/core/Slide'
import { BsTrash } from "react-icons/bs";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

const TablePageable = ({ headers, items, action }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="medium">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} align="center">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhum resultado encontrado!</TableCell></TableRow>)}
                            {items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(action)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página:'}
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                            return '' + from + '-' + to + ' de ' + count
                        }
                    }
                />
            </Paper>
        </Box>
    )
}

const TableActionDeleteRow = ({ titleIconButton, fields, item, onDelete, tableCellPaddingTop, tableCellPaddingBottom }) => {

    const [openDialog, setOpenDialog] = useState(false)

    const onClickCancel = () => {
        setOpenDialog(false)
    }

    const onClickConfirm = () => {
        setOpenDialog(false)
        onDelete();
    }

    const onClickDelete = () => {
        setOpenDialog(true)
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell style={{
                    textAlign: 'center',
                    vertical: 'middle',
                    paddingTop: tableCellPaddingTop,
                    paddingBottom: tableCellPaddingBottom
                }}>
                    <Button className="btn-acao-oficio btn-oficio-lixeira"
                        title={titleIconButton}
                        onClick={onClickDelete}>
                        {
                            <BsTrash
                                style={{ fontSize: 20, color: '#e11717' }} />
                        }
                    </Button>
                </TableCell>
                {fields.map((field, index) => (
                    <TableCell style={{
                        paddingTop: tableCellPaddingTop,
                        paddingBottom: tableCellPaddingBottom
                    }}
                        key={index}
                        component="th"
                        scope="row"
                        align="center">
                        {item[field] ? item[field] : '-'}
                    </TableCell>
                ))}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingTop: 0, paddingBottom: 0, }} colSpan={7}>
                </TableCell>
            </TableRow>

            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle
                    id='alert-dialog-slide-title'
                    className='txt-primario'>Confirma remoção?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        Deseja realmente remover este item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClickCancel}
                        className='bg-primario txt-branco'>Cancelar</Button>
                    <Button
                        onClick={onClickConfirm}
                        className='bg-primario txt-branco'>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    )
}

export { TablePageable, TableActionDeleteRow }