import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Condition from '../../lab/Condition'

const TablePageable = ({ headers, items, action, rowsPerPageOptions, rowsPerPageDefault, onTopTablePagination }) => {

    const getRowsPerPageDefault = () => {
        if (!rowsPerPageDefault)
            return 10

        return rowsPerPageDefault
    };

    const getRowsPerPageOptions = () => {
        if (!rowsPerPageOptions)
            return [5, 10, 25]

        return rowsPerPageOptions
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPageDefault());

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <Condition assert={onTopTablePagination === true}>
                    <TablePagination
                        rowsPerPageOptions={getRowsPerPageOptions()}
                        component="div"
                        count={items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={'Linhas por página:'}
                        labelDisplayedRows={
                            ({ from, to, count }) => {
                                return '' + from + '-' + to + ' de ' + count
                            }
                        }
                    />
                </Condition>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="medium">
                        <TableHead>

                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} align="center">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhum resultado encontrado!</TableCell></TableRow>)}
                            {items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(action)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={getRowsPerPageOptions()}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página:'}
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                            return '' + from + '-' + to + ' de ' + count
                        }
                    }
                />
            </Paper>
        </Box>
    )
}

const TableSimpleRow = ({ item, fields }) => {
    return (
        <Fragment>
            <TableRow >
                <TableCell>
                </TableCell>
                {fields.map((field, index) => (
                    <TableCell key={index} component="th" scope="row" align="center">
                        {item[field] ? item[field] : '-'}
                    </TableCell>
                ))}
            </TableRow>
        </Fragment>
    )
}

const TableCollapseRow = ({ item, fields, onOpen, children, index }) => {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const param = Boolean(searchParams.get('exibirReciboUltimoEnvio'))

    const [open, setOpen] = useState(index === 0 && param)

    const onOpenCollapse = () => {
        setOpen(!open);
        onOpen()
    }

    useEffect(() => {
        if (index === 0 && param) onOpen()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])

    return (
        <Fragment>
            <TableRow >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => onOpenCollapse()}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {fields.map((field, index) => (
                    <TableCell key={index} component="th" scope="row" align="center">
                        {item[field] ? item[field] : '-'}
                    </TableCell>
                ))}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto">
                        {children}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export { TablePageable, TableSimpleRow, TableCollapseRow }