import { Box, Divider, Paper, LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { ViewListAnexos } from '../../../components/fragments/Anexos';
import MessageBox from '../../../components/fragments/MessageBox';
import Snack from '../../../components/fragments/Snack';
import { TableCollapseRow, TablePageable } from '../../../components/fragments/Tables';
import SearchBar from '../../../components/inputs/SearchBar';
import Container from '../../../components/layout/Container';
import EnumSituacaoSustentacaoOral from '../../../enums/enumSituacaoSustentacaoOral';
import { CompareDate, DateTimeFormat, DownloadArquivo, DownloadLocalFile, HandleErrors } from '../../../functions';
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService';
import Condition from '../../../components/lab/Condition';
import { LoadingButtonCancelSend } from '../../../components/fragments/Buttons/CancelSend';

export default function SustentacoesEnviadas(props) {
    const [sustentacoesOrais, setSustentacoesOrais] = useState([]);
    const [filtrarSustentacoes, setFiltrarSustentacoes] = useState(null);
    const [success, setSuccess] = useState('');
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        ProtocoloDigitalService.SustentacoesOraisConsultaGet()
            .then(response => setSustentacoesOrais(carregaSustentacoesOrais(response.data)))
            .catch((err) => setErrors([`Ocorreu um erro inesperado ao consultar as sustentações orais. ${err.response.data.detail}`]))
            .finally(() => setLoader(false));
    }, []);

    const carregaSustentacoesOrais = (data) => {
        return data
            .sort((first, second) => CompareDate(first.dataRegistro, second.dataRegistro))
            .map(row => {

                let _descricaoSituacaoExibirUsuario = '';

                switch (row.situacao) {
                    case EnumSituacaoSustentacaoOral.AguardandoAnalise:
                        _descricaoSituacaoExibirUsuario = 'Aguardando Análise';
                        break;
                    case EnumSituacaoSustentacaoOral.Deferido:
                        _descricaoSituacaoExibirUsuario = 'Deferido';
                        break;
                    case EnumSituacaoSustentacaoOral.Indeferido:
                        _descricaoSituacaoExibirUsuario = 'Indeferido';
                        break;
                    case EnumSituacaoSustentacaoOral.Cancelada:
                        _descricaoSituacaoExibirUsuario = 'Cancelado';
                        break;
                    default:
                        _descricaoSituacaoExibirUsuario = '';
                }

                return {
                    id: row.id,
                    numeroAnoOficio: `${row.oficio.numeroOficio}/${row.oficio.anoOficio}`,
                    dataRegistro: row.dataRegistro ? DateTimeFormat(row.dataRegistro) : null,
                    situacao: row.situacao,
                    descricaoSituacaoExibirUsuario: _descricaoSituacaoExibirUsuario
                }
            });
    }

    const pesquisarSustentacoesOrais = (text) => {
        if (text) {
            setFiltrarSustentacoes(sustentacoesOrais.filter(sustentacao =>
                sustentacao.numeroAnoOficio?.includes(text)));
        } else {
            setFiltrarSustentacoes(sustentacoesOrais);
        }
    }

    const realizaDownloadAnexo = (sustentacaoOral, anexo) => {
        if (!anexo.id) {
            DownloadLocalFile(anexo);
            return;
        }
        setLoader(true);

        ProtocoloDigitalService.SustentacoesOraisConsultaAnexoDownloadGet(sustentacaoOral.id, anexo.id)
            .then(response => DownloadArquivo(response.headers['content-type'], response.headers["content-disposition"], response.data))
            .catch(() => setErrors(['Falha ao baixar o arquivo, tente novamente!']))
            .finally(() => setLoader(false));
    }

    const realizaCancelamentoDoEnvio = (sustentacaoOralId, setLoading, setErrors) => {
        ProtocoloDigitalService.SustentacoesOraisCancelarPut(sustentacaoOralId)
            .then(() => setSuccess('Cancelamento do envio da sustentação oral realizado com sucesso!'))
            .then(() => setTimeout(() => window.location.reload(), 2000))
            .catch(error => setErrors(HandleErrors(error?.response?.data?.detail ? error?.response?.data?.detail : error?.message ? error?.message : error)))
            .finally(() => setLoading(false));
    }

    function AnexosView({ sustentacao }) {
        const [anexos, setAnexos] = useState([]);
        const [loading, setLoading] = useState(false);

        const listarAnexo = (sustentacao) => {
            ProtocoloDigitalService.SustentacoesOraisConsultaAnexoGet(sustentacao.id)
                .then(response => setAnexos(carregaAnexo(response.data)))
                .catch((errors) => {
                    if (errors?.response?.status === 404)
                        setAnexos([])
                    else
                        setErrors(['Falha ao carregar anexo da sustentação oral, tente novamente!']);
                });
        }

        const carregaAnexo = (data) => {
            const anexos = [data];
            return anexos.map(anexo => {
                return {
                    id: anexo.anexoSustentacaoOralId,
                    sustentacaoOralId: anexo.sustentacaoOralId,
                    name: anexo.nomeAnexo
                }
            })
        }

        const onCancelSend = () => {
            setLoading(true);
            realizaCancelamentoDoEnvio(sustentacao.id, setLoading, setErrors);
        }

        function AnexosViewList({ sustentacao, anexos }) {
            return (
                <Box style={{ padding: '10px' }}>
                    <ViewListAnexos
                        anexos={anexos}
                        loading={loading}
                        onClick={(anexo) => realizaDownloadAnexo(sustentacao, anexo)}>
                    </ViewListAnexos>
                    <Condition assert={sustentacao.situacao === EnumSituacaoSustentacaoOral.AguardandoAnalise}>
                        <LoadingButtonCancelSend text='Cancelar' loading={loading} disabled={loading} onCancelSend={onCancelSend} />
                    </Condition>
                </Box>
            )
        }

        return (
            <TableCollapseRow
                item={sustentacao}
                fields={['numeroAnoOficio', 'dataRegistro', 'descricaoSituacaoExibirUsuario']}
                onOpen={() => listarAnexo(sustentacao)}>
                <AnexosViewList sustentacao={sustentacao} anexos={anexos} />
            </TableCollapseRow>
        );
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Sustentações Orais Enviadas">
            <MessageBox title='Alerta' messages={errors} severity='warning' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <SearchBar label='Pesquisar Sustentação Oral' onChange={(text) => pesquisarSustentacoesOrais(text)} />
            </Paper>
            <br /><Divider />
            <LinearProgress hidden={!loader} />
            <TablePageable
                headers={['', 'Número/Ano do Ofício', 'Data de Registro', 'Situação']}
                items={filtrarSustentacoes ? filtrarSustentacoes : sustentacoesOrais}
                action={(sustentacao) => (<AnexosView key={sustentacao.id} sustentacao={sustentacao} />)}
            />
        </Container>
    )
}