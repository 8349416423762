import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { Fragment, useState } from 'react';

const TablePageable = ({ headers, items, action }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="medium">
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} align="center">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhum resultado encontrado!</TableCell></TableRow>)}
                            {items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(action)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página:'}
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                            return '' + from + '-' + to + ' de ' + count
                        }
                    }
                />
            </Paper>
        </Box>
    )
}

const TableActionRow = ({ item, fields, onAction }) => {

    const onActionSend = () => {
        onAction();
    }

    return (
        <Fragment>
            <TableRow>
                <TableCell style={{ textAlign: 'center', vertical: 'middle' }}>
                    <IconButton
                        style={{ color: 'white', background: '#3a5e96' }}
                        title='Visualizar Processo'
                        onClick={() => onActionSend()}>
                        <Visibility />
                    </IconButton>
                </TableCell>
                {fields.map((field, index) => (
                    <TableCell key={index} component="th" scope="row" align="center">
                        {item[field] ? item[field] : '-'}
                    </TableCell>
                ))}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                </TableCell>
            </TableRow>
        </Fragment >
    )
}

export { TablePageable, TableActionRow }