import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    :root {
        --ui-color-01: #ED961D;
        --ui-color-02: #F55414;
        --ui-color-03: #FF0F17;
        --ui-color-04: #D03080;
        --ui-color-05: #9E529F;
        --ui-color-06: #695895;
        --ui-color-07: #59708F;
        --ui-color-08: #59848F;
        --ui-color-09: #5C9076;
        --ui-color-10: #7DAA4C;
        --ui-color-11: #8f2d56;
        --ui-color-12: #f26924;
        --ui-color-13: #8B4513;
    }

    .btn-salvar-default {
        height: 36.5px;
        width: 93px;

        svg {
            color: var(--white);
        }
    }
    span#skeleton-default-input {
        height: 32px;
        width: 100%;
        position: relative;
        transform: scale(1);
    }
 
    .error-form-helper-text {
        color: var(--red);
    }

    #dialog-padrao .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
        max-width: 1024px !important;
        width: 100%;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    fieldset.MuiOutlinedInput-notchedOutline legend{
        max-width: 0 !important;
    }

    .multiline-textfield-with-chips .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .MuiFormControl-root.MuiTextField-root.multiline-textfield-with-chips textarea#outlined-multiline-flexible {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 0 0 10px;
    }

    div#textfield-with-chips {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
    }

    .MuiFormControl-root.MuiTextField-root.multiline-textfield-with-chips textarea#outlined-multiline-flexible {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 0 0 10px;
    }

    div#textfield-with-chips .MuiChip-root.chip-item-textfield-with.MuiChip-deletable {
        background: var(--primary);
        color: var(--white);
    }

    div#textfield-with-chips .MuiChip-root.chip-item-textfield-with.MuiChip-deletable svg {
        color: var(--white);
    }

    .MuiChip-root.chip-item-textfield-with.MuiChip-deletable span.MuiChip-label {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .MuiDialogActions-root.MuiDialogActions-spacing {
        padding: 20px;
    }

    div#alert-dialog-slide-title h2 {
        font-size: 2rem;
    }
`