import { Fragment } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Condition from '../../lab/Condition';

export default function MessageBox({title, messages, severity}) {
    let type = severity || 'error'

    return (
        <Condition assert={messages.length}>
            <Alert severity={type}>
                <AlertTitle>{title}</AlertTitle>
                {messages.map((message, i) => (
                    <Fragment key={i}><strong>{message}</strong><br/></Fragment>
                ))}
            </Alert>
            <br/>
        </Condition>
    )
}