import styled from 'styled-components'

export const Title = styled.h1`
    font-style: normal;
    font-size: 40px;
    line-height: 49px;
    font-weight: 300;
    margin-bottom: 0px;

    @media (max-width: 600px) {
        font-size: 30px;
    }
`

Title.Description = styled.p`
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 45px;
`