import { Box, Divider, Paper, LinearProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { ViewListRecibos } from '../../../components/fragments/Recibos'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import { TableCollapseRow, TablePageable } from '../../../components/fragments/Tables'
import SearchBar from '../../../components/inputs/SearchBar'
import Container from '../../../components/layout/Container'
import { CompareDate, DateTimeFormat, DownloadFile } from '../../../functions'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'

export default function DeclaracoesEnviadas(props) {
    const [listaDeclaracoesEnviadas, setListaDeclaracoesEnviadas] = useState([])
    const [listaFiltradaDeclaracoesEnviadas, setListaDeclaracoesEnviadasFiltradas] = useState(null)
    const [success, setSuccess] = useState('')
    const [errors, setErrors] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const definaListaDeclaracoesEnviadas = () => {
            setLoader(true)
            ProtocoloDigitalService.DeclaracoesEnviadasGet()
                .then(response => setListaDeclaracoesEnviadas(construaListaDeclaracoesEnviadas(response.data)))
                .catch(error => setErrors(['Falha ao consultar declarações enviadas. ' + error.response.data.detail]))
                .finally(() => setLoader(false))
        }
        definaListaDeclaracoesEnviadas()
    }, [])

    const construaListaDeclaracoesEnviadas = (data) => {
        return data
            .sort((first, second) => CompareDate(first.dataRecebimento, second.dataRecebimento))
            .map(row => {
                return {
                    id: row.id,
                    dataRecebimento: DateTimeFormat(row.dataRecebimento),
                    situacao: row.situacao,
                    descricaoSituacao: row.descricaoSituacao,
                    anoCalendario: row.anoCalendario,
                    nomeOrgaoDeclarante: row.orgaoDeclarante.codigo + " - " + row.orgaoDeclarante.nome,
                    numeroRecibo: row.numeroRecibo
                }
            })
    }

    const filtreDeclaracoesEnviadas = (text) => {
        if (text) {
            setListaDeclaracoesEnviadasFiltradas(
                listaDeclaracoesEnviadas.filter(
                    declaracao => declaracao.nomeOrgaoDeclarante.includes(text) ||
                        declaracao.descricaoSituacao.includes(text) ||
                        declaracao.numeroRecibo.includes(text) ||
                        String(declaracao.anoCalendario).includes(text)))
        } else {
            setListaDeclaracoesEnviadasFiltradas(listaDeclaracoesEnviadas)
        }
    }

    const realizaDownloadRecibo = (declaracaoId) => {
        setLoader(true);
        ProtocoloDigitalService.DeclaracoesReciboEntregaGet(declaracaoId)
            .then(response => DownloadFile(response.data.tipoArquivo, response.data.nomeArquivo, response.data.conteudo))
            .catch(() => setErrors(['Falha ao baixar o recibo da declaração.']))
            .finally(() => setLoader(false));
    }

    function ReciboView({ declaracao, index }) {
        const [recibo, setRecibo] = useState(null);

        const exibaNumeroRecibo = (numeroRecibo) => {
            let _recibo = {
                name: `Recibo Nº ${numeroRecibo}`
            };
            setRecibo(_recibo);
        }

        function RecibosViewList({ declaracao, recibo }) {
            return (
                <Box style={{ padding: '10px' }}>
                    <ViewListRecibos
                        recibo={recibo}
                        onClick={() => realizaDownloadRecibo(declaracao.id)}>
                    </ViewListRecibos>
                </Box>
            )
        }

        return (
            <TableCollapseRow
                item={declaracao}
                fields={['anoCalendario', 'nomeOrgaoDeclarante', 'dataRecebimento']}
                onOpen={() => exibaNumeroRecibo(declaracao.numeroRecibo)}
                index={index}
            >
                <RecibosViewList declaracao={declaracao} recibo={recibo} index={index} />
            </TableCollapseRow>
        );
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Declarações de Bens e Rendas Enviadas">
            <MessageBox title='Alerta' messages={errors} severity='warning' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <SearchBar label='Pesquisar Declarações' onChange={(text) => filtreDeclaracoesEnviadas(text)} />
            </Paper>
            <br /><Divider />
            <LinearProgress hidden={!loader} />
            <TablePageable
                headers={['', 'Ano Calendário', 'Órgão Declarante', 'Data de Envio']}
                items={listaFiltradaDeclaracoesEnviadas ? listaFiltradaDeclaracoesEnviadas : listaDeclaracoesEnviadas}
                action={(declaracao, index) => (<ReciboView index={index} key={declaracao.id} declaracao={declaracao} />)}
            />
        </Container>
    )
}