import { FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";

export default function SelectField({ide, title, label, items, value, onSelect}) {
    return (
        <FormControl variant="outlined">
            <InputLabel id={ide}>{title}</InputLabel>
            <Select id="select-tipo-protocolo"
                    labelId={ide} 
                    label={label}
                    value={value}
                    onChange={item => onSelect(item.target.value)}>
                {items.map(item => (
                    <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}