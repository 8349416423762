import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import Condition from '../../lab/Condition'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

export default function DialogConfirmSimple({ openDialog, onCancel, onConfirm, title, questionText }) {
    const handleCancel = () => {
        onCancel()
    }

    const handleConfirm = () => {
        onConfirm()
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby='alert-dialog-slide-title'
                aria-describedby='alert-dialog-slide-description'
            >
                <Condition assert={title !== undefined && title !== ''}>
                    <DialogTitle
                        id='alert-dialog-slide-title'
                        className='txt-primario'>{title}
                    </DialogTitle>
                </Condition>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        {questionText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        className='bg-primario txt-branco'>Cancelar</Button>
                    <Button
                        onClick={handleConfirm}
                        className='bg-primario txt-branco'>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}