import { Avatar, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar } from '@material-ui/core'
import Container from '../../../components/layout/Container'
import { DownloadFile } from '../../../functions'
import { makeStyles } from '@material-ui/core/styles'
import { Description } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService';
import { useState } from 'react';
import { Alert } from '@material-ui/lab';
import Condition from '../../../components/lab/Condition';

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    }
})

export default function Home(props) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState();

    const formularios = [
        { id: 1, name: "Modelo de Petição de Certidão Negativa" },
        { id: 2, name: "Modelo de Petição de Prorrogação de Prazo" },
        { id: 3, name: "Modelo de Petição de Vista Eletrônica" }
    ];

    const onClickBaixarFormulario = (formulario) => {
        setErrors('');
        setLoading(true);

        ProtocoloDigitalService.PeticoesFormulariosGet(formulario)
            .then(response => DownloadFile(response.data.tipoArquivo, response.data.nomeArquivo, response.data.conteudo))
            .catch(() => setErrors('Falha ao baixar formulário, tente novamente'))
            .finally(() => setLoading(false));
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Modelos para Entrega de Documentos ao TCE-GO"
        >
            <Condition assert={!!errors}>
                <Snackbar
                    open={!!errors}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    autoHideDuration={5000}
                    onClose={() => setErrors(false)}>
                    <Alert severity='error' sx={{ width: '100%' }}>
                        {errors}
                    </Alert>
                </Snackbar>
            </Condition>

            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <List>
                                {formularios.map(form => (
                                    <ListItem key={form.id}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Description />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Link to='#' aria-disabled={loading} onClick={() => onClickBaixarFormulario(form.id)} underline="always">
                                                {form.name}
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}