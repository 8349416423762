import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='right' ref={ref} {...props} />
})

export default function ListaInconsistencias({ exibirListaInconsistencias, title, listaRetornoImportacao, onFechar }) {
    const handleFechar = () => {
        onFechar()
    }

    return (
        <div>
            <Dialog
                open={exibirListaInconsistencias}
                TransitionComponent={Transition}
                keepMounted
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                <DialogTitle
                    id='scroll-dialog-title'>{title}
                </DialogTitle>
                <DialogContent
                    dividers={true}>
                    <DialogContentText
                        id='alert-dialog-slide-description'
                        noWrap={false}>
                        {
                            listaRetornoImportacao.map(texto => (
                                <p>{texto}</p>
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleFechar}
                        className='bg-primario txt-branco'>Fechar</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}