import { Divider, Paper, LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import MessageBox from '../../../components/fragments/MessageBox';
import Snack from '../../../components/fragments/Snack';
import { TableActionRow, TablePageable } from '../../../components/fragments/TablesVistaEletronica';
import SearchBar from '../../../components/inputs/SearchBar';
import Container from '../../../components/layout/Container';
import { CompareDate, DateFormat } from '../../../functions';
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService';

export default function VistasConcedidas(props) {
    const [vistaConcedida, setVistasConcedidas] = useState([]);
    const [filtrarVistas, setFiltrarVistas] = useState(null);
    const [success, setSuccess] = useState('');
    const [errors, setErrors] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        ProtocoloDigitalService.VistaEletronicaConsultaGet()
            .then(response => setVistasConcedidas(carregaVistasConcedidas(response.data)))
            .catch((err) => setErrors([`Ocorreu um erro inesperado ao consultar a vista eletrônica concedidas. ${err.response.data.detail}`]))
            .finally(() => setLoader(false));
    }, []);

    const carregaVistasConcedidas = (data) => {
        return data
            .sort((first, second) => CompareDate(first.autuacao.dataAutuacao, second.autuacao.dataAutuacao))
            .map(row => {
                return {
                    id: row.id,
                    numeroProcesso: row.autuacao.numeroProcesso,
                    nomeInteressado: row.autuacao.nomeInteressado,
                    assunto: row.autuacao.assunto,
                    dataAutuacao: row.autuacao.dataAutuacao ? DateFormat(row.autuacao.dataAutuacao) : null,
                    idAutuacao: row.autuacao.id
                }
            });
    }

    const pesquisarVistasConcedidas = (text) => {
        if (text) {
            setFiltrarVistas(vistaConcedida.filter(vista =>
                vista.nomeInteressado?.includes(text.toUpperCase()) ||
                vista.assunto?.includes(text.toUpperCase()) ||
                String(vista.numeroProcesso)?.includes(text) ||
                String(vista.dataAutuacao)?.includes(text)));
        }
        else {
            setFiltrarVistas(vistaConcedida);
        }
    }

    function VistaEletronicaView({ vistaCedida: vista }) {

        const geraLinkProcesso = (vistaCedida) => {
            setLoader(true);
            ProtocoloDigitalService.VistaEletronicaGeraLinkGet(vistaCedida.id, vistaCedida.idAutuacao)
                .then(response => {
                    setLoader(false);
                    let url = response.data;
                    window.open(url, '_blank');
                })
                .catch((errors) => {
                    setLoader(false);
                    setErrors(['Falha ao tentar gerar o link para o processo, tente novamente!']);
                });
        }

        return (
            <TableActionRow
                item={vista}
                fields={['numeroProcesso', 'nomeInteressado', 'assunto', 'dataAutuacao']}
                onAction={() => geraLinkProcesso(vista)}>
            </TableActionRow>
        );
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Vista Eletrônica Concedida">
            <MessageBox title='Alerta' messages={errors} severity='warning' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <SearchBar label='Pesquisar por processo, interessado ou assunto' onChange={(text) => pesquisarVistasConcedidas(text)} />
            </Paper>
            <br /><Divider />
            <LinearProgress hidden={!loader} />
            <TablePageable
                headers={['Ação', 'Número do Processo', 'Interessado(a)', 'Assunto', 'Data de Autuação']}
                items={filtrarVistas ? filtrarVistas : vistaConcedida}
                action={(objVista) => (<VistaEletronicaView key={objVista.id} vistaCedida={objVista} />)}
            />
        </Container>
    )
}