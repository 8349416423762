import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    @media (max-width:450px) {
        background-color: var(--white);
        justify-content: flex-start;
    }
`

export const Card = styled.div`
    position: relative;
    max-width: 600px;
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 1px 3px 1px -1px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 5px 1px rgba(0,0,0,0.12);

    @media (max-width: 450px) {
        box-shadow: 1px 3px 1px -1px rgba(0,0,0,0), 0px 1px 1px 0px rgba(0,0,0,0), 0px 0px 5px 1px rgba(0,0,0,0);
    }
`

export const CardContent = styled.div`
    padding: 0 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .errorLoginHelper {
        color: var(--red);
        text-align: center;
    }

    & .MuiFormControl-root.FormControl {
        width: 100%;
        margin-bottom: 20px;

        svg.MuiSvgIcon-root.iconLogin {
            color: var(--gray);
        }
    }

    & .checkbox {
        & span.MuiFormControlLabel-label {
            font-size: 14px;
        }
        & .MuiCheckbox-colorPrimary.Mui-checked {
            color: var(--primary);
        }
    }

    & .link {
        font-weight: 900;
        font-size: 13px;
        color: var(--primary);
        text-decoration: none !important;
        position: relative;
        top: 8px;
        left: 33px;
        text-align: right;
    }

    & .button-tce {
        background: var(--primary) !important;
        color: var(--white);
        margin-top: 34px;
        margin-bottom: 30px;
    }

    @media (max-width:450px) {
        padding: 0 20px;

        & .recuperarSenha {
            display: flex;
            flex-direction: column;
            & .MuiGrid-grid-xs-6 {
                width: 100%;
                max-width: 100%;
            }
            & .MuiGrid-grid-xs-6 a {
                top: 0;
                left: 0
            }
        }
    }
`

CardContent.GovBR = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: -10px;

    .button-login-govbr {
        background: var(--white);
        border: 1px solid #d8d8d8ad;
        text-transform: none;
        font-size: 16px;
        color: var(--primary);
        border-radius: 100px;
        padding: 12px 22px;
    }

    .separator-login-govbr {
        content: "";
        margin: 0 12px;
        width: 1px;
        height: 22px;
        background: #d8d8d8;
    }
`

export const LoadingArea = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.64);
    border-radius: 8px;
    overflow: hidden;
`

export const Logo = styled.img`
    width: auto;
    height: 55px;
    margin: 30px 0 0 0;
`

export const Title = styled.p`
    font-size: 1.35rem;
    font-weight: 400;
    line-height: 1.35rem;
    letter-spacing: 0em;
    margin: 12px 0 5px 0;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
`
export const Description = styled.p`
    color: rgba(0, 0, 0, 0.54);
    margin: 0 0 40px 0;
`

export const Copyright = styled.p`
    font-size: 12px;
    width: 100%;
    text-align: left;
    position: absolute;
    bottom: -41px;
    left: 13px;
`

export const Symbol = styled.span`
    font-size: 21px;
    position: relative;
    top: 6px;
`