import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'
import { DownloadFile, HandleErrors } from '../../../functions'
import Container from '../../../components/layout/Container'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import { Grid, Paper, CardContent, FormControl, TextField, Divider, CardActions, Link, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import { ViewCreateAnexos } from '../../../components/fragments/Anexos'
import { LoadingButtonSave } from '../../../components/fragments/Buttons/Save'
import { LoadingButtonLink } from '../../../components/fragments/Buttons/Link'
import { LoadingButtonCancel } from '../../../components/fragments/Buttons/Cancel'
import { LoadingButtonSearch } from '../../../components/fragments/Buttons/Search'
import enumModalidadeEnvioDBR from '../../../enums/enumModalidadeEnvioDBR'
import ListaInconsistencias from './ListaInconsistencias'
import Condition from '../../../components/lab/Condition'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    },
    divider: {
        width: '95%',
        margin: 'auto'
    },
    footer: {
        padding: '20px',
    }
})

export default function InformarDeclarantes(props) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState('')
    const [listaOrgaosDeclarantes, setListaOrgaosDeclarantes] = useState([])
    const [orgaoDeclarante, setOrgaoDeclarante] = useState({})
    const [anoCalendario, setAnoCalendario] = useState('')

    const [listaAnexosRelacaoDeclarantes, setListaAnexosRelacaoDeclarantes] = useState([])
    const [listaRetornoImportacao, setListaRetornoImportacao] = useState([])
    const [exibirListaInconsistencias, setExibirListaInconsistencias] = useState(false)

    const tamanhoMaximoBytesEnvio = 314572800

    // Para carregar os dados dos órgãos.
    useEffect(() => {
        const construaListaOrgaosDeclarantes = (listaOrgaosDeclarantes) => {
            let _listaOrgaosDeclarantes = []

            if (listaOrgaosDeclarantes !== undefined && listaOrgaosDeclarantes.length > 0) {
                _listaOrgaosDeclarantes = listaOrgaosDeclarantes.map(
                    function (row) {
                        return { code: row.id, label: row.codigo + " - " + row.nome }
                    }
                )
            }

            return _listaOrgaosDeclarantes
        }

        const sugiraOrgao = (orgaoSugerido) => {
            if (orgaoSugerido !== null &&
                orgaoSugerido !== undefined &&
                orgaoSugerido.id !== null &&
                orgaoSugerido.id !== undefined &&
                orgaoSugerido.id !== 0) {
                setOrgaoDeclarante({ code: orgaoSugerido.id, label: orgaoSugerido.codigo + " - " + orgaoSugerido.nome })
            }
        }

        const definaOrgaosDeclarantes = () => {
            ProtocoloDigitalService.DeclaracoesOrgaosDeclarantesGet()
                .then(response => {
                    setListaOrgaosDeclarantes(construaListaOrgaosDeclarantes(response.data))
                    ProtocoloDigitalService.DeclaracoesOrgaoLotacaoUsuarioLogadoGet()
                        .then(response => {
                            sugiraOrgao(response.data)
                        })
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
        }

        definaOrgaosDeclarantes()
    }, [])

    const limiteCampoAnoCalendario = (e) => {
        e.currentTarget.maxLength = 4
        let valor = e.currentTarget.value
        e.target.value = valor.replace(/\D/g, "")
        setAnoCalendario(e.target.value)
    }

    const valideExtensaoArquivo = arquivo => {
        if (arquivo.type.length > 0) {
            var nomeArquivo = String(arquivo.name)
            var extensao = nomeArquivo.toLowerCase().substr(nomeArquivo.lastIndexOf('.') + 1, nomeArquivo.length)
            switch (extensao) {
                case 'xls':
                    return true
                case 'xlsx':
                    return true
                default:
                    return false
            }
        }
    }

    function valideDadosRelacaoDeclarantes() {
        setErrors([])

        if (!orgaoDeclarante || !orgaoDeclarante.code || orgaoDeclarante.code === '' || orgaoDeclarante.code === null || orgaoDeclarante.code === 0) {
            setErrors(['O órgão declarante deve ser informado.'])
            return false
        }

        if (anoCalendario === '' || anoCalendario == null) {
            setErrors(['O ano calendário deve ser informado.'])
            return false
        }

        if (anoCalendario === 0 || anoCalendario === "0") {
            setErrors(['O ano calendário informado é inválido.'])
            return false
        }

        if (anoCalendario < 1924) {
            setErrors(['O Ano Calendário da declaração é inválido.'])
            return false
        }

        if (anoCalendario >= new Date().getFullYear()) {
            setErrors(['O Ano Calendário da declaração é inválido.'])
            return false
        }

        if (listaAnexosRelacaoDeclarantes === undefined || listaAnexosRelacaoDeclarantes.length === 0) {
            setErrors(['A planilha de importação de declarantes deve ser informada.'])
            return false
        }

        let _listaAnexosRelacaoDeclarantes = listaAnexosRelacaoDeclarantes;
        let somaTamanhosArquivos = 0

        for (let indice = 0; indice < _listaAnexosRelacaoDeclarantes.length; indice++) {
            if (!valideExtensaoArquivo(_listaAnexosRelacaoDeclarantes[0])) {
                setErrors([`O tipo do arquivo ${_listaAnexosRelacaoDeclarantes[0].name} não é permitido.`])
                return false
            }
            somaTamanhosArquivos = somaTamanhosArquivos + _listaAnexosRelacaoDeclarantes[0].size
        }

        if (somaTamanhosArquivos > tamanhoMaximoBytesEnvio) {
            setErrors([`A(s) planilha(s) excel não podem possuir mais de 300 MB.`])
            return false
        }

        return true
    }

    const onClickBaixarModeloPlanilhaAnexoRelacaoDeclarantes = () => {
        setErrors([]);
        setLoading(true);

        ProtocoloDigitalService.DeclaracoesModeloPlanilhaDeclarantesGet()
            .then(response => DownloadFile(response.data.tipoArquivo, response.data.nomeArquivo, response.data.conteudo))
            .catch((err) => setErrors([`Falha ao baixar modelo de planilha, ${err.response.data.detail}!`]))
            .finally(() => setLoading(false));
    }

    // Para cancelar os dados do declarante e anexos informados na edição.
    const limpeRelacaoDeclarantes = () => {
        setErrors([])
        setAnoCalendario('')
        setOrgaoDeclarante({ code: 0, label: '' })
        setListaAnexosRelacaoDeclarantes([])
        setExibirListaInconsistencias(false)
        setListaRetornoImportacao([])
    }

    function ocorreramInconsistenciasImportacao(listaRetornoImportacao) {
        for (let indice = 0; indice < listaRetornoImportacao.length; indice++) {
            if (listaRetornoImportacao[indice].toLowerCase().includes('inconsistências')) return true
        }
        return false
    }

    // Para importar a relação de declarantes.
    const importeRelacaoDeclarantes = () => {
        if (valideDadosRelacaoDeclarantes()) {
            setLoading(true)
            ProtocoloDigitalService.DeclaracoesRelacaoDeclarantesPost(
                orgaoDeclarante.code,
                anoCalendario,
                enumModalidadeEnvioDBR.AtualizacaoAnual,
                listaAnexosRelacaoDeclarantes)
                .then(response => {
                    if (Array.isArray(response.data)) {
                        if (response.data.length > 0) {
                            setListaRetornoImportacao(response.data)
                            if (ocorreramInconsistenciasImportacao(response.data)) {
                                setErrors([`Ocorreram inconsistências ao tentar importar alguns CPFs de declarantes.`])
                                setSuccess('Relação de declarantes salva parcialmente.')
                            }
                            else {
                                setSuccess('Relação de declarantes salva com sucesso.')
                            }
                            setExibirListaInconsistencias(true)
                        }
                    }
                    setListaAnexosRelacaoDeclarantes([])
                })
                .catch(error => setErrors(HandleErrors(error.response.data.detail)))
                .finally(() => setLoading(false))
        }
    }

    const onClickExibirListaInconsistencias = () => {
        setExibirListaInconsistencias(true)
    }

    const obtenhaTituloListaInconsistencias = (listaInconsistenciasImportacao) => {
        let mensagemInconsistencia = 'Todos os registros foram importados com sucesso.'

        if (ocorreramInconsistenciasImportacao(listaInconsistenciasImportacao))
            mensagemInconsistencia = 'Inconsistências encontradas na importação de declarantes. Será necessário ajustar as inconsistências na planilha excel para que seja novamente anexada e importada.'

        return mensagemInconsistencia
    }

    const onFecharListaInconsistencias = () => {
        setExibirListaInconsistencias(false)
    }

    const themeTooltip = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                    fontWeight: 100
                }
            }
        }
    })

    return (
        <Container
            crumbs={props.crumbs}
            title='Informar Declarantes de Bens e Rendas'>
            <MessageBox title='Atenção' messages={errors} severity='info' />
            <Snack
                message={success}
                severity='success'
                onClose={() => setSuccess(false)} />
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Autocomplete
                                        id='orgao-declarante'
                                        options={listaOrgaosDeclarantes}
                                        clearText={'Limpar'}
                                        openText={'Expandir'}
                                        closeText={'Recolher'}
                                        noOptionsText={'Nenhum resultado encontrado'}
                                        value={orgaoDeclarante}
                                        getOptionSelected={(option) => option.label}
                                        getOptionLabel={(option) => option.label || ''}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null &&
                                                newValue.code !== null &&
                                                newValue.code !== undefined) {
                                                setOrgaoDeclarante({ code: newValue.code, label: newValue.label })
                                            }
                                            else {
                                                setOrgaoDeclarante({ code: 0, label: '' })
                                            }

                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Órgão Declarante *"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <FormControl>
                                        <ThemeProvider theme={themeTooltip}>
                                            <Tooltip title="Refere-se ao ano em que ocorreram os fatos geradores da declaração.">
                                                <TextField
                                                    label='Ano Calendário *'
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    variant='outlined'
                                                    type='text'
                                                    value={anoCalendario}
                                                    onChange={item => limiteCampoAnoCalendario(item)} />
                                            </Tooltip>
                                        </ThemeProvider>
                                    </FormControl>
                                </Grid>
                                <Divider className={classes.divider} />
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Link href='#' onClick={onClickBaixarModeloPlanilhaAnexoRelacaoDeclarantes} underline="always">
                                        Baixar modelo de planilha para importação de declarantes
                                    </Link>
                                </Grid>
                                <Divider className={classes.divider} />
                                <ViewCreateAnexos
                                    files={listaAnexosRelacaoDeclarantes}
                                    setFiles={setListaAnexosRelacaoDeclarantes}
                                    multiple={true}
                                />
                            </Grid>
                            <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*São permitidos somente arquivos excel nos formatos XLS e XLSX, com tamanho máximo de 300MB.</p>
                            <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*No arquivo excel, a coluna "CPF" é obrigatória. Serão considerados para o "CPF" apenas os valores informados com números (de 0 a 9), pontos (.) e traços (-), não sendo obrigatório informar ponto (.) e traço(-).</p>
                            <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*No arquivo excel, a coluna "Nome" não é obrigatória. Não serão considerados para o "Nome" os valores informados, pois o nome do declarante é obtido através dos dados do próprio TCE-GO.</p>

                            <ListaInconsistencias
                                exibirListaInconsistencias={exibirListaInconsistencias}
                                title={obtenhaTituloListaInconsistencias(listaRetornoImportacao)}
                                listaRetornoImportacao={listaRetornoImportacao}
                                onFechar={() => onFecharListaInconsistencias()} />
                        </CardContent>
                        <br />
                        <Divider className={classes.divider} />
                        <Grid container justifyContent='flex-end' spacing={2}>
                            <CardActions className={classes.footer}>
                                <LoadingButtonCancel text='Limpar' loading={loading} disabled={loading} onCancel={limpeRelacaoDeclarantes} />
                                <Condition assert={ocorreramInconsistenciasImportacao(listaRetornoImportacao)}>
                                    <LoadingButtonSearch text='Inconsistências' loading={loading} disabled={loading} onSearch={onClickExibirListaInconsistencias} />
                                </Condition>
                                <Condition assert={
                                    orgaoDeclarante !== undefined && orgaoDeclarante.code > 0 &&
                                    anoCalendario !== undefined && anoCalendario !== 0 && anoCalendario !== '0' && anoCalendario.length === 4
                                }>
                                    <LoadingButtonLink text='Gestão de Declarações' loading={loading} disabled={loading} link={'/DeclaracaoBensRendas/GestaoDeclaracoes/' + orgaoDeclarante.code + '/' + anoCalendario} />
                                </Condition>
                                <LoadingButtonSave text='Importar' loading={loading} disabled={loading} onSave={importeRelacaoDeclarantes} />
                            </CardActions>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}