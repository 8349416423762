import styled from 'styled-components'

export const CardCustom = styled.div`
    width: 100%;
    border: 1px solid #dbdfea;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 8px;
    height: 100%;
`

CardCustom.Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    button#btn-acess-link {
        padding: 1.5rem;
        position: relative;
        display: block;
        line-height: 1.3;
        white-space: inherit;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: Roboto,sans-serif;
        line-height: 1;
        border: none;
        background: rgba(0,0,0,0);
        transition: all .3s ease;
        white-space: nowrap;
        width: 100%;
        text-transform: capitalize;
        border-top: 1px solid #dbdfea;
        min-height: 60px;
        height: 100%;
        color: var(--primary);

        span.MuiButton-label {
            justify-content: space-between;
            display: flex;
            text-transform: capitalize;
            font-size: .85rem;
            font-weight: 500;
        }
    }
`

CardCustom.Info = styled.div`
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-start;

    @media (max-width: 764px) {
        flex-direction: column;
    }
`

CardCustom.ImageBox = styled.div`
    margin-right: 1.5rem;
    margin-bottom: 0;
    width: 84px;
    flex-shrink: 0;
    text-align: center;
    padding: 0.125rem;

    @media (max-width: 764px) {
        margin-bottom: 1.0rem;
    }
`

CardCustom.Image = styled.img`
    width: 100%;
    height: auto;
`

CardCustom.InfoBox = styled.div`

`

CardCustom.Title = styled.div`
    font-weight: 700;
    line-height: 1.1;
    color: #364a63;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
`

CardCustom.Description = styled.div`
    text-align: left;
    color: #8094ae;
`

CardCustom.AdditionalInfo = styled.div`
    flex-direction: row;
    align-items: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    display: flex;
    justify-content: flex-start;
`

CardCustom.Children = styled.div`
    width: 100%;

    & > div {
        padding: 1.5rem;
    }

`