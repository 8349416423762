import { Tooltip, Button, CircularProgress } from "@material-ui/core"
import { SendOutlined } from "@material-ui/icons"
import { Fragment } from "react"
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Condition from '../../../lab/Condition'

const LoadingButtonSend = ({ text, textTooltip, disabled, loading, onSend }) => {

    const themeTooltip = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                    fontWeight: 100
                }
            }
        }
    })

    return (
        <Fragment>
            <Condition assert={textTooltip === undefined}>
                <Button style={{ float: 'right' }} variant="outlined" endIcon={<SendOutlined />} color="primary" disabled={disabled} onClick={onSend}>
                    {text}
                    {loading && <CircularProgress size={24} />}
                </Button>
            </Condition>
            <Condition assert={textTooltip !== undefined}>
                <ThemeProvider theme={themeTooltip}>
                    <Tooltip title={textTooltip}>
                        <Button style={{ float: 'right' }} variant="outlined" endIcon={<SendOutlined />} color="primary" disabled={disabled} onClick={onSend}>
                            {text}
                            {loading && <CircularProgress size={24} />}
                        </Button>
                    </Tooltip>
                </ThemeProvider>
            </Condition>
            <br /><br />
        </Fragment>
    )
}

export { LoadingButtonSend }