import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

export default function Snack({message, severity, onClose}) {
    let type = severity || 'success'
    let isOpen = !!message
    
    return (
        <Snackbar open={isOpen} 
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                autoHideDuration={5000}
                onClose={onClose}>
            <Alert severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}