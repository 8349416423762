import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography, Tooltip } from '@material-ui/core';
import { FileCopyOutlined, AttachFile, AttachFileRounded, Delete, CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import { Fragment } from 'react';
import { DistinctList } from '../../../functions';
import Condition from '../../lab/Condition';
import { OutputLink } from '../Typography';
import { createTheme as createThemeTooltip, ThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const ViewListAnexos = ({ title, description, anexos, onClick, onRemove, children }) => {
    return (
        <Fragment>
            <Typography variant="subtitle2" gutterBottom component="div" style={{ wordBreak: 'break-word' }}>
                {title}
            </Typography>
            <Typography variant="body2" gutterBottom component="span" style={{ wordBreak: 'break-word' }}>
                {description}
            </Typography>
            <Divider />

            {children ? children : null}

            <List>
                {anexos.map(anexo => (
                    <ListItem key={anexo.name}>
                        <ListItemAvatar>
                            <Avatar>
                                <Condition assert={anexo.consistente === undefined}>
                                    <AttachFile />
                                </Condition>
                                <Condition assert={anexo.consistente === true}>
                                    <CheckCircleOutline />
                                </Condition>
                                <Condition assert={anexo.consistente === false}>
                                    <ErrorOutline />
                                </Condition>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <OutputLink onClick={() => onClick(anexo)} text={anexo.name} />
                        </ListItemText>
                        <Condition assert={onRemove}>
                            <ListItemIcon>
                                <IconButton onClick={() => onRemove(anexo)}>
                                    <Delete />
                                </IconButton>
                            </ListItemIcon>
                        </Condition>
                    </ListItem>
                ))}
            </List>
        </Fragment>
    )
}

const ViewEditAnexos = ({ title, description, anexos, setAnexos, onClick, onRemove, children, tamanhoMaximoNomeAnexo }) => {

    const { enqueueSnackbar } = useSnackbar()

    const onFileUpload = (event) => {
        if (!event.target.files.length) return;
        setAnexos(DistinctList([...anexos, ...event.target.files], 'name'));

        if (tamanhoMaximoNomeAnexo !== undefined && tamanhoMaximoNomeAnexo > 0 && event.target.files[0].name.length > tamanhoMaximoNomeAnexo) {
            enqueueSnackbar(`O nome do arquivo de anexo deve possuir no máximo ${tamanhoMaximoNomeAnexo} caracteres.`, { variant: 'info' })
        }
    }

    return (
        <Fragment>
            <ViewListAnexos
                title={title}
                description={description}
                anexos={anexos}
                onClick={onClick}
                onRemove={onRemove}
            >
                {children}
            </ViewListAnexos>
            <IconButton component="label" onClick={e => e.target.value = null} >
                <input type="file" onChange={(event) => onFileUpload(event)} hidden multiple />
                <AttachFileRounded color="primary" fontSize="large" />
            </IconButton>
            <span>Adicionar Anexos</span>
            <Divider />
            <br />
        </Fragment>
    )
}

const theme = createThemeTooltip({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 15,
                color: "white",
                backgroundColor: "black",
                fontWeight: 100
            }
        }
    }
})

const ViewCreateAnexos = ({ files, setFiles, multiple, anexarArquivosDBR, usuarioLogadoGestorRH, tamanhoMaximoNomeAnexo }) => {

    const { enqueueSnackbar } = useSnackbar()

    const onFileUpload = (event) => {
        if (!event.target.files.length) return;
        setFiles(old => DistinctList([...old, ...event.target.files], 'name'));

        if (tamanhoMaximoNomeAnexo !== undefined && tamanhoMaximoNomeAnexo > 0 && event.target.files[0].name.length > tamanhoMaximoNomeAnexo) {
            enqueueSnackbar(`O nome do arquivo de anexo deve possuir no máximo ${tamanhoMaximoNomeAnexo} caracteres.`, { variant: 'info' })
        }
    }

    const onFileRemove = (item) => {
        setFiles(files.filter(file => file.name !== item.name))
    }

    return (
        <Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <IconButton component="label" onClick={e => e.target.value = null} >
                    <input type="file" onChange={onFileUpload} hidden multiple={multiple} />
                    <Condition assert={anexarArquivosDBR === true}>
                        <Condition assert={usuarioLogadoGestorRH === true}>
                            <ThemeProvider theme={theme}>
                                <Tooltip title="Modo Coletivo: Não preencha o campo 'CPF do Interessado' e informe arquivos cujos nomes contenham, dentre outros caracteres, uma sequência numérica com 11 dígitos (sem pontos e traços) para representar o CPF e o sistema preencherá automaticamente os dados dos interessados. Importante que os zeros à esquerda sejam informados para completar a sequência numérica de 11 dígitos. Isso pode ser feito para diversos interessados (múltiplos arquivos PDF) de uma só vez. Modo Individual: Preencha o campo 'CPF do Interessado' e todos os arquivos serão atribuídos para o CPF informado.">
                                    <FileCopyOutlined color="primary" fontSize="large" />
                                </Tooltip>
                            </ThemeProvider>
                        </Condition>
                        <Condition assert={usuarioLogadoGestorRH !== true}>
                            <FileCopyOutlined color="primary" fontSize="large" />
                        </Condition>
                    </Condition>
                    <Condition assert={anexarArquivosDBR !== true}>
                        <AttachFileRounded color="primary" fontSize="large" />
                    </Condition>
                </IconButton>
                <Condition assert={anexarArquivosDBR === true}>
                    <span>Clique aqui para selecionar os arquivos da DBR a serem anexados</span>
                    <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>Selecione o(s) arquivo(s) da DBR a serem anexados no sistema. Após selecionar os arquivo(s), clique no botão "Anexar Arquivos" para anexar as DBR(s) selecionadas e depois no botão "Transmitir DBR" para completar processo de envio da DBR.</p>
                </Condition>
                <Condition assert={anexarArquivosDBR !== true}>
                    <span>Anexar Arquivo</span>
                </Condition>

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <List>
                    {files.map(file => (
                        <ListItem key={file.name}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AttachFile />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.name} />
                            <ListItemIcon>
                                <IconButton onClick={() => onFileRemove(file)}>
                                    <Delete />
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Fragment>
    );
}

export { ViewListAnexos, ViewEditAnexos, ViewCreateAnexos }