import webConfig from '../webconfig.json'
import axios from 'axios'

const TOKEN_KEY = `@${webConfig.nomeSistema}`
const USER_DATA = `@${webConfig.nomeSistema}-data`

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

export const logout = () => {
    removeLocalStorageData()
    window.location.href=`${webConfig.ApiLogin}/api/v1/AutenticacaoGovSso/logout?redirect=${window.location.origin}`;
}

export const removeLocalStorageData = () => {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_DATA)
}

export const getUserData = () => localStorage.getItem(USER_DATA)

export const validaToken = async () => {
    let dataUser = JSON.parse(getUserData())
    await axios({
        method: 'get',
        url: `${webConfig.ApiLogin}/api/v1/AutenticacaoGovSso/token/validate?usuario=${dataUser.id}`,
        headers: {
            'authorization': getToken()
        }
    })
        .catch(error => {
            logout()
        })
}