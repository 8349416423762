
import { NavBarComponent } from 'tce-componentes-ui-react'
import { logout } from '../../../auth';

import { CloneElementByClass } from '../../../functions'
import LogoSistema from '../../../assets/img/logo-protocolo-eletronico.svg'


export default function Navigator(props) {

    const handleNavbarClick = (event) => {
        if (event?.target?.innerText === 'Sair') {
            logout();
        } else {
            CloneElementByClass('userdata-exit-app');
        }
    }

    return (
        <div onClick={handleNavbarClick}>
            <NavBarComponent
                logoSistema={LogoSistema}
                nomeSistema={props.nomeSistema}
                ImgLogoTCEGO={LogoSistema}
                dataMenus={props.dataMenus}
            />
        </div>
    )
}