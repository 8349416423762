import { Grid } from "@material-ui/core"
import Container from '../../components/layout/Container'
import { Title } from "../../components/ElementsUI/Title"
import Card from "../../components/Surfaces/Card"
import ImgProtocolarPeticao from '../../../src/static/img/essay.png';
import ImgProtocolarSustentacao from '../../../src/static/img/megafone.png';
import ImgProtocolarDeclaracao from '../../../src/static/img/documents_goverment.png';
import ImgVistasConcedidas from '../../../src/static/img/authority.png';

export default function Home(props) {
    return (
        <Container
            crumbs={props.crumbs}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Title>Acesso Rápido</Title>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card
                        image={ImgProtocolarPeticao}
                        title="Petição"
                        description="Protocolar Petição"
                        link={`/Peticao/ProtocolarPeticao`}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card
                        image={ImgProtocolarSustentacao}
                        title="Sustentação Oral"
                        description="Protocolar Sustentação Oral"
                        link={`SustentacaoOral/ProtocolarSustentacaoOral`}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card
                        image={ImgProtocolarDeclaracao}
                        title="Declaração de Bens e Rendas"
                        description="Protocolar Declaração de Bens e Rendas"
                        link={`/DeclaracaoBensRendas/ProtocolarDeclaracao`}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card
                        image={ImgVistasConcedidas}
                        title="Vista Eletrônica"
                        description="Vistas Eletrônicas Concedidas"
                        link={`/VistaEletronica/VistasConcedidas`}
                    />
                </Grid>
            </Grid>
        </Container >
    )
}