import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, FormControl, Select, MenuItem } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Fragment, useState } from 'react'
import Condition from '../../lab/Condition'
import EnumModalidadeEnvioDBR from '../../../enums/enumModalidadeEnvioDBR'
import Snack from '../Snack'


const TablePageable = ({ headers, items, action }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size='medium'>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} align='center'>{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 && (<TableRow><TableCell colSpan={6} style={{ textAlign: 'center' }}>Nenhum resultado encontrado!</TableCell></TableRow>)}
                            {items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(action)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Linhas por página:'}
                    labelDisplayedRows={
                        ({ from, to, count }) => {
                            return '' + from + '-' + to + ' de ' + count
                        }
                    }
                />
            </Paper>
        </Box>
    )
}

const TableCollapseRow = ({ interessado, fields, onChangeModalidade, children }) => {
    const [successInteressado, setSuccessInteresado] = useState('')
    const [open, setOpen] = useState(false);
    const [modalidadeEnvio, setModalidadeEnvio] = useState(interessado['modalidadeEnvio'])

    const onOpenCollapse = () => {
        setOpen(!open);
    }

    const definaModalidadeEnvio = (event) => {
        setModalidadeEnvio(event.target.value)
        interessado['modalidadeEnvio'] = event.target.value
        onChangeModalidade(interessado, setSuccessInteresado)
    }

    return (
        <Fragment>
            <TableRow >
                <TableCell>
                    <Snack
                        message={successInteressado}
                        severity='success'
                        onClose={() => setSuccessInteresado(false)} />
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => onOpenCollapse()}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {fields.map((field, index) => (
                    <TableCell key={index} component="th" scope="row" align="center" >
                        <Condition assert={field !== 'descricaoModalidadeEnvio'}>
                            {interessado[field] !== '' ? (interessado[field] === true ? 'SIM' : (interessado[field] === false ? 'NÃO' : interessado[field])) : '-'}
                        </Condition>
                        <Condition assert={field === 'descricaoModalidadeEnvio'}>
                            <FormControl variant='outlined'>
                                <Select
                                    variant='outlined'
                                    labelId='select-outlined-label-modalidade-envio'
                                    id='select-outlined-modalidade-envio'
                                    value={modalidadeEnvio}
                                    label=''
                                    placeholder=' '
                                    onChange={definaModalidadeEnvio}
                                >
                                    <MenuItem value={EnumModalidadeEnvioDBR.AtualizacaoAnual}>Atualização Anual</MenuItem>
                                    <MenuItem value={EnumModalidadeEnvioDBR.EntradaServicoPublico}>Entrada no Serviço Público</MenuItem>
                                    <MenuItem value={EnumModalidadeEnvioDBR.SaidaServicoPublico}>Saída do Serviço Público</MenuItem>
                                </Select>
                            </FormControl>
                        </Condition>
                    </TableCell>
                ))}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto">
                        {children}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export { TablePageable, TableCollapseRow }