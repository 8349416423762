import { CardActions, CardContent, Divider, FormControl, Grid, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { ViewCreateAnexos } from '../../../components/fragments/Anexos'
import { LoadingButtonSend } from '../../../components/fragments/Buttons/Send'
import MessageBox from '../../../components/fragments/MessageBox'
import Snack from '../../../components/fragments/Snack'
import Container from '../../../components/layout/Container'
import { HandleErrors } from '../../../functions'
import ProtocoloDigitalService from '../../../services/ProtocoloDigitalService'

const useStyles = makeStyles({
    root: {
        minWidth: '100%',
        borderRadius: '20px'
    },
    divider: {
        width: '95%',
        margin: 'auto'
    },
    footer: {
        padding: '20px',
    }
})

export default function ProtocolarSustentacao(props) {
    const classes = useStyles()
    const [files, setFiles] = useState([]);
    const [anoOficio, setAnoOficio] = useState('');
    const [numeroOficio, setNumeroOficio] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [multiple] = useState(false);
    const tamanhoMaximoBytesEnvio = 314572800;

    const limitaCampoNumeroOficio = (e) => {
        e.target.value = (e.target.value).toString().slice(0, 18);
        setNumeroOficio(e.target.value);
    }

    const limitaCampoAnoOficio = (e) => {
        e.target.value = (e.target.value).toString().slice(0, 4);
        setAnoOficio(e.target.value);
    }

    const validaExtensaoArquivo = file => {
        if (file.type.length > 0) {
            var nomeArquivo = String(file.name);
            var extensao = nomeArquivo.toLowerCase().substr(nomeArquivo.lastIndexOf('.') + 1, nomeArquivo.length);
            switch (extensao) {
                case 'avi':
                case 'mov':
                case 'mpeg':
                case 'rmvb':
                case 'mkv':
                case 'aac':
                case 'wma':
                case 'mp4':
                case 'mp3':
                case 'ac3':
                case 'wav':
                case 'ogg':
                case 'm4v':
                    return true;
                default:
                    return false;
            }
        }
    }

    const enviarSustentacaoOral = () => {
        setErrors([]);
        setLoading(true);

        if (numeroOficio === '' || numeroOficio === null) {
            setErrors(['Número do Ofício deve ser informado.']);
            setLoading(false);
            return;
        }

        if (numeroOficio === 0 || numeroOficio === "0") {
            setErrors(['Número do Ofício informado é inválido.']);
            setLoading(false);
            return;
        }

        if (anoOficio === '' || anoOficio == null) {
            setErrors(['Ano do Ofício deve ser informado.']);
            setLoading(false);
            return;
        }

        if (anoOficio === 0 || anoOficio === "0") {
            setErrors(['Ano do Ofício informado é inválido.']);
            setLoading(false);
            return;
        }

        if (!files.length) {
            setErrors(['É necessário anexar a sustentação oral.']);
            setLoading(false);
            return;
        }

        if (files.length > 1) {
            setErrors(['É permitido anexar somente um arquivo para sustentação oral.']);
            setLoading(false);
            return;
        }

        if (!validaExtensaoArquivo(files[0])) {
            setErrors([`O tipo do arquivo ${files[0].name} não é permitido.`]);
            setLoading(false);
            return;
        }

        if (files[0].size > tamanhoMaximoBytesEnvio) {
            setErrors(['Tamanho máximo suportado para envio do anexo é de 300MB']);
            setLoading(false);
            return;
        }

        ProtocoloDigitalService.SustentacoesOraisUploadPost(numeroOficio, anoOficio, files)
            .then(() => setSuccess('Sustentação oral enviada com sucesso!'))
            .then(() => setTimeout(() => props.history.push('/SustentacaoOral/SustentacoesEnviadas'), 2000))
            .catch(error => setErrors(HandleErrors(error.response.data.detail)))
            .finally(() => setLoading(false));
    }

    return (
        <Container
            crumbs={props.crumbs}
            title="Protocolar Sustentação Oral"
        >
            <MessageBox title='Atenção' messages={errors} severity='info' />
            <Snack message={success} severity='success' onClose={() => setSuccess(false)} />

            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.root} elevation={3}>
                        <CardContent>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <TextField
                                            label="Número do Ofício *"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            variant="outlined"
                                            type="number"
                                            value={numeroOficio}
                                            onChange={item => limitaCampoNumeroOficio((item))} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FormControl>
                                        <TextField
                                            label="Ano do Ofício *"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            variant="outlined"
                                            type="number"
                                            value={anoOficio}
                                            onChange={item => limitaCampoAnoOficio(item)} />
                                    </FormControl>
                                </Grid>
                                <Divider className={classes.divider} />
                                <ViewCreateAnexos
                                    files={files}
                                    setFiles={setFiles}
                                    multiple={multiple}
                                />
                            </Grid>
                            <p style={{ fontWeight: '500', color: 'orangered', fontSize: '12px' }}>*São permitidos somente os formatos AVI, MOV, MPEG, MKV, ACC, WMA, MP3, MP4, AC3, WAV, OGG e M4V com tamanho máximo de 300MB.</p>
                        </CardContent>
                        <br />
                        <Divider className={classes.divider} />
                        <CardActions className={classes.footer}>
                            <Grid container justifyContent="flex-end">
                                <LoadingButtonSend text='Enviar' loading={loading} disabled={loading} onSend={enviarSustentacaoOral} />
                            </Grid>
                        </CardActions>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}