import { Typography } from "@material-ui/core"

const OutputText = ({title, value}) => {
    return (
        <Typography variant="body2" gutterBottom component="div" style={{wordBreak: 'break-word'}}>
            <b>{title}:</b> {value}
        </Typography>
    )
}

const WarningText = ({title, value}) => {
    return (
        <Typography variant="body2" gutterBottom component="div"  style={{color: 'orangered'}}>
            <b>{title}:</b> {value}
        </Typography>
    )
}

const OutputLink = ({text, onClick}) => {
    return (
        <span style={{color: 'royalblue', textDecoration: 'underline', cursor: 'pointer'}} onClick={onClick}>
            {text}
        </span>
    )
}

export {OutputText, OutputLink, WarningText}